import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const MinWidthWarning = ({ minWidth, onWidthCheck }) => {
    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    useEffect(() => {
        const checkScreenWidth = () => {
            const narrow = window.innerWidth < minWidth;
            setIsNarrowScreen(narrow);
            onWidthCheck(narrow);
        };

        window.addEventListener('resize', checkScreenWidth);
        checkScreenWidth();  // Check on initial render

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, [minWidth, onWidthCheck]);

    if (isNarrowScreen) {
        return (
            <div className="notification is-danger">
                Minimum of {minWidth}px screen width required.  Please increase the window size.
            </div>
        );
    }

    return null;
};

export default MinWidthWarning;