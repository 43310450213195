import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { sendAPIRequest } from '../../utils';

const CollaboratorsTab = ({ selectedBot }) => {
    const [ inviteModalVisible, setInviteModalVisible ] = useState(false);
    const [ inviteEmail, setInviteEmail ] = useState('');
    const [ validEmail, setValidEmail ] = useState(false);

    useEffect(() => {
        if (inviteEmail) {
            const valid = validateEmail(inviteEmail);
            setValidEmail(valid);
        }
    }, [inviteEmail]);

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    return (
    <div> 
        <button class="button is-primary is-small"
            onClick={() => setInviteModalVisible(true)}
            // disabled={selectedBot.owner_user_id !== 1}
            >Invite</button>
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>User Email</th>
                    <th>Role</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{selectedBot.owner_user_email}</td>
                    <td>Owner</td>
                    <td></td>
                </tr>
                {selectedBot.collaborator_user_emails.map(collaborator => (
                    <tr>
                        {/* <td>{collaborator.name}</td> */}
                        <td>{collaborator.email}</td>
                        <td>Collaborator</td>
                        <td>
                            <button class="button is-danger is-small"
                            >Remove</button>
                        </td>
                    </tr>
                ))}

                {selectedBot.invite_user_emails.map(collaborator => (
                    <tr>
                        <td>{collaborator.email}</td>
                        <td>Invited</td>
                        <td>
                            <button class="button is-danger is-small"
                            >Remove</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {JSON.stringify(selectedBot)}

        <div class={"modal " + (inviteModalVisible ? "is-active" : "")}>
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card
                    -title">Add a collaborator to {selectedBot.botname}</p>
                    <button class="delete" aria-label="close"
                    onClick={() => setInviteModalVisible(false)}></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">Email</label>
                        <input class="input is-small" type="text"
                            placeholder="user@email.com"
                            value={inviteEmail}
                            onInput={(e) => setInviteEmail(e.target.value)}
                        />
                    </div>

                </section>
                <footer class="modal-card-foot">
                    <button className='button is-primary'
                        disabled={!validEmail}
                        onClick={async () => {
                            // await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/invite_user_email`, 'POST', { email: inviteEmail });
                            selectedBot.invite_user_emails.push({ email: inviteEmail });
                            setInviteEmail('');
                            setInviteModalVisible(false);
                        }}
                    >Invite</button>
                    <button className='button'
                        onClick={() => setInviteModalVisible(false)}
                    >Cancel</button>
                </footer>
            </div>
        </div>
    </div>
)};

export default CollaboratorsTab;