import { h } from "preact";
import { useState, useMemo } from "preact/hooks";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import config from "../../../config";
const server_api = config.serverAPIHost;
const google_client_id = config.googleClientId;

// const credential = ""
// console.log("decode credential", jwtDecode(credential));
// console.log("decode credential", Object.keys(jwtDecode(credential)));

const LoginForm = ({ onLoginSuccess, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const trim_email = email.trim();
    setEmail(trim_email);
  };

  const handlePwdChange = (e) => {
    const pwd = e.target.value;
    const trim_pwd = pwd.trim();
    setPassword(trim_pwd);
  };

  const isEmailValid = useMemo(() => {
    const trim_email = email.trim();
    if (trim_email === "") {
      return true;
    }
    return validateEmail(trim_email);
  }, [email]);

  const resetDisable = useMemo(
    () => !email || !isEmailValid,
    [email, isEmailValid]
  );

  const loginDisable = useMemo(
    () => !email || !isEmailValid || !password || password.length < 8,
    [email, isEmailValid, password]
  );

  const handleResetPasswordRequest = async (event) => {
    event.preventDefault();
    const response = await fetch(
      `${server_api}/api/v1/users/reset-password-request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email, // Assuming you're using email as username
        }),
      }
    );
    try {
      const data = await response.json();
    } catch (error) {
    } finally {
      setMessage(
        `An email has been sent to ${email} with password reset instructions, if it's in our system. Please check your inbox.`
      );
      return;
    }
  };

  const handleEmailLogin = async (event) => {
    event.preventDefault();

    const response = await fetch(
      `${server_api}/api/v1/users/authentication/email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email, // Assuming you're using email as username
          password: password,
        }),
      }
    );

    const data = await response.json();

    if (data.access_token) {
      onLoginSuccess(data); // Notify parent component of successful login
      return;
    }
    setMessage(data.msg ? `Login failed: ${data.msg}` : "Login failed");
  };

  const responseGoogleSuccess = async (response) => {
    const credential = response.credential;

    const auth_response = await fetch(
      `${server_api}/api/v1/users/authentication/google`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jwt: credential,
          env: config.branch == "release" ? "production" : "development",
        }),
      }
    );
    const data = await auth_response.json();
    if (data.access_token) {
      onLoginSuccess(data); // Notify parent component of successful login
      return;
    }
    setMessage(data.msg ? `Login failed: ${data.msg}` : "Login failed");
  };

  const responseGoogleFailure = (response) => {
    setMessage("Google Login failed");
  };

  return (
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head" style={{ background: "#5ECEB3" }}>
          <p class="modal-card-title"></p>
          <button class="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section class="modal-card-body">
          <GoogleOAuthProvider clientId={google_client_id}>
            <GoogleLogin
              onSuccess={responseGoogleSuccess}
              onError={responseGoogleFailure}
              useOneTap
            />
          </GoogleOAuthProvider>

          <div className="mt-3">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  class={`input ${isEmailValid ? "" : "is-danger"}`}
                  type="text"
                  placeholder="Email"
                  value={email}
                  onInput={handleEmailChange}
                />
                {!isEmailValid && (
                  <p class="help is-danger">Invalid email format</p>
                )}
              </div>
            </div>
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onInput={handlePwdChange}
                />
                password should be at least 8 chars long.
              </div>
            </div>
            <div class="field">
              <div class="control">
                <button
                  class="button is-primary is-small"
                  disabled={loginDisable}
                  onClick={handleEmailLogin}
                >
                  Login
                </button>
                {/* reset password */}
                <button
                  class="button is-small ml-3"
                  disabled={resetDisable}
                  onClick={handleResetPasswordRequest}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
          {
            message && <p>{message}</p>
          }

          <p className="mt-6">
            By clicking the Login button or the Sign in button, you are
            accepting to Site Chat's <a href="/terms-of-use">terms</a>,{" "}
            <a href="/privacy-policy">privacy policy</a>, and{" "}
            <a href="/cookie-policyy">cookie policy</a>. If you don't have an
            account, an account will be created for you.
          </p>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </div>
  );
};

export default LoginForm;
