# Set up for a Wix

## Create a Site Chat account
Your first step is to set up a [Site Chat account](https://www.site-chat.com/console), unless you already have one. Skip to creating a property unless you want to create a separate account for this website and/or app. For example, you might want to create another account if this website and/or app belong(s) to a separate business.


## Add a Site Chat bot to your wix site

### Open Site Chat Console
1. Sign in to your [Site Chat account](https://www.site-chat.com/console).
2. In the left sidebar, click **Create a Bot** or an created bot.  Configure the bot if needed.  Any changes will be saved automatically and most of changes will take effect immediately.  *You can always come back and change the bot configuration later.*
3. Turn on the **Live Mode**.
4. Click the **Get code** button next to the Bot ID.  You will see a code block pop up.

   ![intergration_note](/assets/documentation/intergration/intergration_note.png)

### Update Wix page
1. Go to your site's editor and click on "Add" (+) on the left side.
2. Select "More" and then "HTML Code".
3. Drag the HTML Code widget to your footer area.
   ![wix_1.png](/assets/documentation/intergration/wix/wix_1.png)
4. Click "Enter Code" and paste your HTML code into the box.
   ![wix_2.png](/assets/documentation/intergration/wix/wix_2.png)
   
```javascript
    <!-- Site Chat Script and Tag -->
    <script data-bot-id="C-your-bot-id" id="site-chat-script" 
        src="https://www.site-chat.com/lib/site-chat.js" type="module">
    </script>
```

5. Click "Apply" to save the changes.



### Next steps
After setting up your Site Chat bot, it's highly recommended to simulate user behavior and use Customer Insight Analytics tools for guidance in enhancing your bot. Regularly visit your [Site Chat console](https://www.site-chat.com/console) to monitor user interactions and observe how their appreciation for your bot evolves over time.