import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import GlobalStateContext from '../../context/GlobalStateContext';
import logo from '../../../assets/logo.png';
import './style.css';


const Header = () => {
    const { isUserLoggedIn, triggerLoginModalOn } = useContext(GlobalStateContext);
    const [isActive, setIsActive] = useState(false);
    const toggleNavbar = () => {
        setIsActive(!isActive);
    };
    return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={logo} alt="Site Chat Logo" />
          </a>
          <a role="button" className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} 
            aria-label="menu" aria-expanded="false" 
            onClick={toggleNavbar}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
            <div class="navbar-start">
                <a class="navbar-item" href="/docs/integrations/shopify">
                    Documents
                </a>
                <a class="navbar-item" href="/docs/tutorials/conversion_tracking">
                    Tutorials
                </a>
                <a class="navbar-item" href="/docs/stories/story_1">
                    Stories
                </a>
                {/* <a class="navbar-item" href="/features">
                    Features
                </a>
                <a class="navbar-item" href="/pricing">
                    Pricing
                </a>
                <a class="navbar-item" href="/contact">
                    Contact
                </a> */}
            </div>
          <div className="navbar-end">
            <div className="navbar-item">
                <div className="buttons">
                    {!isUserLoggedIn && (
                        <button className="button is-primary is-small" onClick={triggerLoginModalOn}>
                            <strong>Start</strong>
                        </button>
                    )}
                    {isUserLoggedIn && (
                        <button className="button is-primary is-small" onClick={() => window.location.href='/console'}>
                            <strong>Console</strong>
                        </button>
                    )}
                </div>
            </div>
          </div>
        </div>
      </nav>
    );
}

export default Header;
