import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import GlobalStateContext from './GlobalStateContext';
import LoginForm from '../components/login_form';
import { setLocalStorageItem, sendAPIRequest } from '../utils';

const GlobalStateProvider = ({ children }) => {
	const generateAnonId = () => {
		const uuidv4 = () => {
			return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
				const r = Math.random() * 16 | 0;
				const v = c === 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		};
		return 'anon_' + uuidv4();
	}
	
	const getUserIdOrAnon = () => {
		let userId = localStorage.getItem('user_id');	
		if (!userId) {
			userId = generateAnonId();
			setLocalStorageItem('user_id', userId);
		}
		return userId;
	}

    const [user_id, setUserId] = useState(getUserIdOrAnon());
    const [access_token, setAccessToken] = useState(localStorage.getItem('access_token'));
    const [refresh_token, setRefreshToken] = useState(localStorage.getItem('refresh_token'));
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!access_token);

    const onLoginSuccess = (user_auth) => {
        setUserId(user_auth.user_id);
        localStorage.setItem('user_id', user_auth.user_id);
        setAccessToken(user_auth.access_token);
        localStorage.setItem('access_token', user_auth.access_token);
        setRefreshToken(user_auth.refresh_token);
        localStorage.setItem('refresh_token', user_auth.refresh_token);
        setShowLoginModal(false);
        setIsUserLoggedIn(true);
        window.location.href = '/console';
    };

    const triggerLoginModalOn = () => {
        setShowLoginModal(true);
    };

    const triggerLoginModalOff = () => {
        setShowLoginModal(false);
    };

    const logout = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setUserId(null);
        setAccessToken(null);
        setRefreshToken(null);
        setIsUserLoggedIn(false);
    };

    useEffect(() => {
        localStorage.setItem('user_id', user_id);
    }, [user_id]);

    return (
        <GlobalStateContext.Provider value={{ 
            user_id, access_token, isUserLoggedIn, 
            logout, triggerLoginModalOn, triggerLoginModalOff,
        }}>
            {children}
            {showLoginModal && 
                <LoginForm 
                    onLoginSuccess={onLoginSuccess} 
                    onClose={() => setShowLoginModal(false)} />}
        </GlobalStateContext.Provider>
    );
}

export default GlobalStateProvider;
