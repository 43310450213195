import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { sendAPIRequest } from '../../utils';
import io from 'socket.io-client';
import Markdown from 'markdown-to-jsx';
import './ChatHistoryTab.css';

import config from '../../../config';
const server_site_host = config.serverSiteHost;
const server_api_host = config.serverAPIHost;

const TextMessage = ({ botTheme, content, sender_type }) => {
    const markdownOptions = {
        overrides: {
            a: {
                component: (props) => <a {...props} target="_blank" rel="noopener noreferrer" />,
            },
        },
    };
    return (
        <div class={`text_message`}
            style={{
                background: sender_type === 'client' ? botTheme.clientTextMessageBackgroundColor : botTheme.botTextMessageBackgroundColor,
                color: sender_type === 'client' ? botTheme.clientTextMessageColor : botTheme.botTextMessageColor,
            }}
        >
            <Markdown options={markdownOptions}>{content}</Markdown>
        </div>
    );
};


const ChatHistorySection = ({ bot_id, thread_id, botTheme, botAvatar, chatMessages, fetchEarlierChatMessages }) => {
	const [messageReviews, setMessageReviews] = useState({});
	useEffect(async () => {
		if (chatMessages.length == 0) {
			return;
		}
		const message_start_time = chatMessages[0].created_at;
		const message_end_time = chatMessages[chatMessages.length - 1].created_at;
		const review_list = await sendAPIRequest(`/api/v1/${bot_id}/bot_message_reviews/${thread_id}?message_start_time=${message_start_time}&message_end_time=${message_end_time}`, 'GET');
		const message_reviews = {};
		review_list.forEach((review) => {
			message_reviews[review.reference_message_id] = review;
		});
		setMessageReviews(message_reviews);
	}, [chatMessages]);

	const getPositiveReviewCount = (message_id) => {
		return messageReviews[message_id] ? messageReviews[message_id].positive_count : 0;
	}

	const getNegativeReviewCount = (message_id) => {
		return messageReviews[message_id] ? messageReviews[message_id].negative_count : 0;
	}

	const increasePositiveReviewCount = async (message_id) => {
		const review = messageReviews[message_id] || {
			reference_message_id: message_id,
			positive_count: 0,
			negative_count: 0,
		};
		const response = await sendAPIRequest(`/api/v1/${bot_id}/bot_message_reviews/${message_id}`, 'POST', {
			...review,
			positive_count: review.positive_count + 1,
		});
		setMessageReviews(prev => ({
			...prev,
			[message_id]: response,
		}));
	}

	const increaseNegativeReviewCount = async (message_id) => {
		const review = messageReviews[message_id] || {
			reference_message_id: message_id,
			positive_count: 0,
			negative_count: 0,
		};
		const response = await sendAPIRequest(`/api/v1/${bot_id}/bot_message_reviews/${message_id}`, 'POST', {
			...review,
			negative_count: review.negative_count + 1,
		});
		setMessageReviews(prev => ({
			...prev,
			[message_id]: response,
		}));
	}

	const [dialogueHeight, setDialogueHeight] = useState(window.innerHeight * 0.9);
	const messageListRef = useRef(null);
	// const isScrolledToBottomRef = useRef(true); // Assume we start at the bottom
    // const prevScrollHeightRef = useRef(0); // New ref to track the previous scroll height    

	const handleScroll = async () => {
        const element = messageListRef.current;
        // scrolled to top
        if (element && element.scrollTop === 0) {
			// debugger
			if (chatMessages.length === 0) {
				return;
			}
			await fetchEarlierChatMessages(chatMessages[0]._id);
        }
    };

	return (<div class="message_thread" 
		ref={messageListRef} 
		onScroll={handleScroll} 
		style={{ 
			height: `${dialogueHeight}px`,
			display: 'block',
		}}
	>
		{chatMessages.map((msg, index) => (
			<div key={`${msg._id}_${msg.content.length}`} style={{margin: '1em'}}>
			{msg.sender_type === 'client' ? (
				<div class="level-right is-flex" style={{alignItems: 'flex-start'}}>
					<div style={{width: '100%'}}>
						<div>
							<div style={{fontSize: '70%', textAlign: 'right', fontWeight: 'bold'}}>
								{msg.sender_bot_name}
							</div>
							{msg.message_type === 'image' && (
								<img src={`${server_api}/api/files/${msg.content}`} alt="User's image message R" class="mr-3 image_message" />
							)}
							{msg.message_type === 'text' && (
								<p class="has-text-left" style={{paddingLeft: '48px'}}>
									<TextMessage
										botTheme={botTheme}
										content={msg.content} 
										sender_type={msg.sender_type}
									/>
								</p>
							)}
						</div>
					</div>
				</div>
			) : (
				<div class="level is-flex" style={{alignItems: 'flex-start'}}>
					<div class="level-left" style={{flex: '0 0 48px'}}>
						<div class="level-item">
							<figure class="image is-48x48">
								<img class="is-rounded" src={botAvatar} alt="Bot avatar" />
							</figure>
						</div>
					</div>
					<div style={{width: '100%', paddingLeft: '1rem', paddingRight: '2rem'}}>
						<span>
							<div style={{fontSize: '70%', fontWeight: 'bold'}}>{msg.sender_bot_name} </div>
							{msg.type === 'image' ? (
								<img src={`${server_api}/api/files/${msg.content}`} alt="User's image message L2" class="image_message" />
								) : (
									<TextMessage 
										botTheme={botTheme}
										content={msg.content} 
										sender_type={msg.sender_type}
										stream_mode={msg.stream}
									/>
								)}
						</span>						
					</div>
					{/* bot message review icons */}
					<div className="mt-2" style={{ color: 'black' }}>
						<div className="field">
							<div className="control">
								<div className="is-flex" style={{ alignItems: 'center' }}>
									<i className="material-icons mr-2" style={{ fontSize: '0.8rem', color: '#4a4a4a', cursor: 'pointer' }}
									onClick={() => increasePositiveReviewCount(msg._id)}
									>thumb_up</i> {getPositiveReviewCount(msg._id)}
								</div>
							</div>
						</div>
						<div className="field">
							<div className="control">
								<div className="is-flex" style={{ alignItems: 'center' }}>
									<i className="material-icons mr-2" style={{ fontSize: '0.8rem', color: '#4a4a4a', cursor: 'pointer' }}
									onClick={() => increaseNegativeReviewCount(msg._id)}
									>thumb_down</i> {getNegativeReviewCount(msg._id)}
								</div>
							</div>
						</div>
					</div>
				</div>
				)}
			</div>
		))}
	</div>)
}

const ChatHistoryTab = ({ selectedBot }) => {
	// all loaded threads of the bot
	const [threads, setThreads] = useState([]);
	const threadsRef = useRef(null);

	const [botNewMessageSocket, setBotNewMessageSocket] = useState(null);
	// const botNewMessageSocketRef = useRef(botNewMessageSocket);
	// useEffect(() => {
    //     botNewMessageSocketRef.current = botNewMessageSocket;
    // }, [botNewMessageSocket]);
	useEffect(async () => {
		setThreads([]);
		threadsRef.current = threads;

		setBotNewMessageSocket(null);
		if (!selectedBot) {
			return;
		}
		// TODO: need a filter and a sort order, otherwise it will be too many
		// - or use pagination, and update if it is the 1st page
		const chatThreads = await sendAPIRequest(`/api/v1/chats/${selectedBot._id}/threads`, 'GET')
		if (chatThreads.length) {
			setSelectedThread(chatThreads[0]);
		}
		// TODO: if not use pagination, increase # of threads gradually, and listen on botNewMessageSocket
		setThreads(chatThreads);
		threadsRef.current = chatThreads;
		setBotNewMessageSocketSetup();

		return () => {
            botNewMessageSocket && botNewMessageSocket.disconnect();
			chatThreadSocket && chatThreadSocket.disconnect();
        };
	}, [selectedBot]);

	const setBotNewMessageSocketSetup = () => {
        // set/reset the socket connection
        botNewMessageSocket && botNewMessageSocket.disconnect();
        const chatbotNewMessageSocket = io(server_api_host, { autoConnect: false });
        setBotNewMessageSocket(chatbotNewMessageSocket);
        setBotNewMessageSocketLiseners(chatbotNewMessageSocket)
        chatbotNewMessageSocket.connect();
    }

	const setBotNewMessageSocketLiseners = (chatThreadSocket) => {
		chatThreadSocket.on('new_message_event', (event) => {
			const thread_id = event.thread_id
			const updated_at = event.updated_at
			// TODO: add or update the thread list, resort by updated_at
			const threads = threadsRef.current;
			const updatedThreads = threads.map((thread) => {
				if (thread._id === thread_id) {
					return {
						...thread,
						updated_at,
					}
				}
				return thread;
			});
			updatedThreads.sort((a, b) => {
				return b.updated_at - a.updated_at;
			});
			setThreads(updatedThreads);
		});

		chatThreadSocket.on('connect', () => {
            chatThreadSocket.emit('join_bot_new_message_event_listener', selectedBot._id);    
        });
        
        chatThreadSocket.on('disconnect', (reason) => {
            console.log('ChatThreadSocket: Disconnected from the server. Reason:', reason);
        });
        
        chatThreadSocket.on('reconnect', (attemptNumber) => {
            console.log('ChatThreadSocket: Reconnected to the server. Attempt number:', attemptNumber);
            // DO NOT reemit 'join_room', already handled by 'connect' event
        });
	}

	// chat history of the selected thread
	const [selectedThread, setSelectedThread] = useState(null);	
	const selectedThreadRef = useRef(selectedThread);
	const [threadSocket, setThreadSocket] = useState(null); 
	const [threadChatHistory, setThreadChatHistory] = useState([]);
    useEffect(async () => {
        setThreadChatHistory([]);
        if (!selectedThread) {
            return;
        }
		selectedThreadRef.current = selectedThread;		
        await fetchEarlierChatMessages();
        chatThreadSetup();
    }, [selectedThread]);    

	const fetchEarlierChatMessages = async (last_message_id = null) => {
        if (!selectedThreadRef.current) {
            return;
        }
        let endpoint = `/api/v1/chats/${selectedBot._id}/threads/${selectedThreadRef.current._id}/messages?per_page=5`;
        if (last_message_id) {
            endpoint += `&last_message_id=${last_message_id}`;
        }
        const response = await sendAPIRequest(endpoint, 'GET');
        const messages = Array.isArray(response.messages) ? response.messages : [];
        setThreadChatHistory(prevMessages => [...messages, ...prevMessages]);
    };

	const chatThreadSetup = () => {
        // set/reset the socket connection
        threadSocket && threadSocket.disconnect();
        const chatThreadSocket = io(server_api_host, { autoConnect: false });
        setThreadSocket(chatThreadSocket);
        setThreadSocketLiseners(chatThreadSocket)
        chatThreadSocket.connect();
    }

	const setThreadSocketLiseners = (chatThreadSocket) => {
		// received a full message
		chatThreadSocket.on('chat_message', (message) => {
			const m_id = message._id;
			// replace the message with the same id or append it
			setThreadChatHistory(prev => {
				const new_messages = prev.map(m => {
					if (m._id == m_id) {
						return message;
					}
					return m;
				});
				if (new_messages.filter(m => m._id == m_id).length == 0) {
					new_messages.push(message);
				}
				return new_messages;
			});
		});

		// message stream, the message is not complete yet.  partial content might be lost previously
		chatThreadSocket.on('chat_message_stream_continue', (message) => {
			// replace the message with the same id or append it
			// const m_id = message._id;
			// setThreadChatHistory(prev => {
			// 	const existingMessageIndex = prev.findIndex(m => m._id === message._id);
			// 	if (existingMessageIndex !== -1) {
			// 		const updatedMessages = prev.map((m, index) =>
			// 			index === existingMessageIndex ? {...message, stream: true, content: m.content + message.content} : m
			// 		);
			// 		return updatedMessages;
			// 	} else {
			// 		return [...prev, message];
			// 	}
			// });
		});		

		// message stream, the message is complete now.  replace the message with the same id as previous content might be incomplete
		chatThreadSocket.on('chat_message_stream_complete', (message) => {
			setThreadChatHistory(prev => {
				const existingMessageIndex = prev.findIndex(m => m._id === message._id);
		
				if (existingMessageIndex !== -1) {
					const updatedMessages = prev.map((m, index) =>
						index === existingMessageIndex ? {...message, stream: false, content: m.content} : m
					);
					return updatedMessages;
				} else {
					return [...prev, message];
				}
			});
		});	
	
		chatThreadSocket.on('connect', () => {
			chatThreadSocket.emit('join_room', {
				thread_id: selectedThread._id,
				bot_id: selectedBot._id,
			});    
		});
		
		chatThreadSocket.on('disconnect', (reason) => {
			console.log('Disconnected from the server. Reason:', reason);
		});
		
		chatThreadSocket.on('reconnect', (attemptNumber) => {
			console.log('Reconnected to the server. Attempt number:', attemptNumber);
			// DO NOT reemit 'join_room', already handled by 'connect' event
		});
	}

	const lastMessageTimeFormatter = (last_message_timestamp) => {
		// last_message_timestamp: epoch time in seconds, check the local time
		// if today, show HH:mm
		// if yesterday, show Yesterday
		// if this year, show DD MMM 
		// otherwise, show DD MMM YYYY
		const today = new Date();
		const today_date = today.getDate();
		const today_month = today.getMonth();
		const today_year = today.getFullYear();
		const today_timestamp = new Date(today_year, today_month, today_date).getTime();
		const yesterday_timestamp = today_timestamp - 24 * 60 * 60 * 1000;
		const last_message_date = new Date(last_message_timestamp * 1000);
		const last_message_date_timestamp = new Date(last_message_date.getFullYear(), last_message_date.getMonth(), last_message_date.getDate()).getTime();
		if (last_message_date_timestamp === today_timestamp) {
			return last_message_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
		} else if (last_message_date_timestamp === yesterday_timestamp) {
			return 'Yesterday';
		} else if (last_message_date.getFullYear() === today_year) {
			return last_message_date.toLocaleDateString([], { day: 'numeric', month: 'short' });
		} else {
			return last_message_date.toLocaleDateString([], { day: 'numeric', month: 'short', year: 'numeric' });
		}

	}

	return (<div>
		{/* <label class="label">TODO Filter and Sorter</label>		 */}
		<div className='columns'>
			<aside className={`menu column is-1`}
				style={{
					width : '180px',
					borderRight: '2px solid #ECECEC',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<ul class="menu-list">
					{selectedThread && threads.map((thread) => (
						<li>
						<a
							className={thread._id === selectedThread._id ? 'navbar-item has-text-primary' : ''}
							style={thread._id === selectedThread._id ? {
									background: '#EFFFFC',
									fontWeight: 'bold',
								} : {}
							}
							onClick={() => { setSelectedThread(thread) }}
						>
							<div className='level'>
								<div className='level-left'>
									<div class="level-item">
										<span>{thread._id.slice(-6)}</span>
									</div>
								</div>
								<div className='level-right'>
									<div class="level-item">
										<h6 class="subtitle is-7">{lastMessageTimeFormatter(thread['updated_at'])}</h6>
									</div>
								</div>
							</div>
						</a>
						</li>
					))}
				</ul>
			</aside>
			<div className={`menu column`}
				style={{
					height: '100%',
					overflowY: 'scroll',
				}}>
				{selectedThread && selectedBot && <ChatHistorySection 
					bot_id={selectedBot._id}
					thread_id={selectedThread._id} 
					botAvatar={selectedBot.avatar} 
					botTheme={selectedBot.theme} 
					chatMessages={threadChatHistory} 
					fetchEarlierChatMessages={fetchEarlierChatMessages}
				/>}
			</div>
		</div>
	</div>)
}

export default ChatHistoryTab;