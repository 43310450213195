# Set up for Squarespace

## Create a Site Chat account
Your first step is to set up a [Site Chat account](https://www.site-chat.com/console), unless you already have one. Skip to creating a property unless you want to create a separate account for this website and/or app. For example, you might want to create another account if this website and/or app belong(s) to a separate business.


## Add a Site Chat bot to your Squarespace site

### Open Site Chat Console
1. Sign in to your [Site Chat account](https://www.site-chat.com/console).
2. In the left sidebar, click **Create a Bot** or an created bot.  Configure the bot if needed.  Any changes will be saved automatically and most of changes will take effect immediately.  *You can always come back and change the bot configuration later.*
3. Turn on the **Live Mode**.
4. Click the **Get code** button next to the Bot ID.  You will see a code block pop up.

   ![intergration_note](/assets/documentation/intergration/intergration_note.png)

### Update Squarespace page 
The following steps requires [Squarespace premium features](https://support.squarespace.com/hc/en-us/articles/115015517328)
available in Business, Commerce, Professional, and Premium plans.


#### Add a code block
To add a code block:

1. Edit a page or post, click **Add Block** or an insert point, then click **Code**. For help, visit [Adding content with blocks](https://support.squarespace.com/hc/articles/206543757).
2. Open the block editor by clicking the **pencil** icon on the block.
3. [Add your code](https://support.squarespace.com/hc/articles/206543757) in the text field.

```javascript
    <!-- Site Chat Script and Tag -->
    <script data-bot-id="C-your-bot-id" id="site-chat-script" 
        src="https://www.site-chat.com/lib/site-chat.js" type="module">
    </script>
```

3. If you're using the code block to [display code snippets](https://support.squarespace.com/hc/en-us/articles/206543167#toc-display-source), switch the **Display Source** toggle on.


### Next steps
After setting up your Site Chat bot, it's highly recommended to simulate user behavior and use Customer Insight Analytics tools for guidance in enhancing your bot. Regularly visit your [Site Chat console](https://www.site-chat.com/console) to monitor user interactions and observe how their appreciation for your bot evolves over time.