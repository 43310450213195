import config from '../config';
const server_api = config.serverAPIHost;
export const stripe = config.stripe;

export const chat_site_official_bot_id = config.chat_site_official_bot_id

export function setLocalStorageItem(key, value) {
    if (!value || value === 'null') {
        return removeLocalStorageItem(key);
    }
    const oldValue = localStorage.getItem(key);
    if (oldValue !== value) {
        localStorage.setItem(key, value);
        const event = new Event('localstoragechange');
        event.key = key;
        event.oldValue = oldValue;
        event.newValue = value;
        window.dispatchEvent(event);
    }
}

export function removeLocalStorageItem(key) {
    if (!localStorage.getItem(key)) {
        return;
    }
    localStorage.removeItem(key);
    const event = new Event('localstoragechange');
    event.key = key;
    window.dispatchEvent(event);
}

export async function sendAPIRequest(endpoint, method = 'GET', body = null, headers = {}) {
    const defaultHeaders = {
        'Content-Type': 'application/json'
    };

    if (localStorage.getItem('access_token')) {
        defaultHeaders['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    }

    const config = {
        mode: 'cors',
        method,
        headers: { ...defaultHeaders, ...headers },
        // credentials: 'include'  // Include this if you're handling cookies
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    const response = await fetch(`${server_api}${endpoint}`, config);

    if (response.status === 401) {
        const response_body = await response.json();
        if (response_body.detail === "Token has expired" || response_body.detail === "Not authenticated") {
            localStorage.removeItem('access_token');
            // refresh access using refresh token
            if (localStorage.getItem('refresh_token')) {
                const refresh_response = await fetch(`${server_api}/api/v1/users/token/refresh`, {
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`
                    },
                    // credentials: 'include'  // Include this if you're handling cookies
                });

                if (refresh_response.ok) {
                    const refresh_data = await refresh_response.json();
                    setLocalStorageItem('access_token', refresh_data.access_token);
                    return sendAPIRequest(endpoint, method, body, headers);
                } else {
                    // logout user to force login
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user_id');
                }
            }
        }
    }

    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || JSON.stringify(data.detail) || 'Failed to fetch.');
    }

    const data = await response.json();
    return data;
}

export async function fetchImageBase64(imageSrc) {
    // Check if the source is already a base64 data URI
    if (imageSrc.startsWith('data:')) {
        return imageSrc;
    }

    // If it's a blob URL, fetch the blob and convert it to base64
    const response = await fetch(imageSrc, {
        mode: 'cors'
    });
    const blob = await response.blob();
    
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}
