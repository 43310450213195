import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { sendAPIRequest } from '../../utils';
import aiStarIcon from '../../../assets/icons/ai_star.svg';


const QAFineTuneTab = ({ selectedBot }) => {
	const [qaPerPage, setQAPerPage] = useState(5);
	const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
	const [totalPaginationPages, setTotalPaginationPages] = useState(1);

	const [qaList, setQAList] = useState([]);
	const [qaListSortOrder, setQAListSortOrder] = useState('desc'); // asc or desc

	const [ userInputTimer, setUserInputTimer ] = useState(null);
	const [ isGeneratingQA, setIsGeneratingQA ] = useState(false);

	const [ showQAHintbar, setShowQAHintbar ] = useState(true);
	
	useEffect(() => {
		// Clean up the timer on unmount
		return () => clearTimeout(userInputTimer);
	}, [userInputTimer]);

	useEffect(async () => {
		const qa_list = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/qa_list`, 'GET')
		qa_list.sort((a, b) => {
			if (qaListSortOrder === 'dec') {
				return a.created_at - b.created_at;
			} else {
				return b.created_at - a.created_at;
			}
		})
		setQAList(qa_list);
	}, [selectedBot._id]);

	useEffect(() => {
		setTotalPaginationPages(Math.ceil(qaList.length / qaPerPage));		
	}, [qaList, qaPerPage]);

	const updateQA = async (qa) => {
		const new_qa = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/qa_list/${qa._id}`, 'PUT', qa);
		setQAList((prevQAList) => {
			const qaIndex = prevQAList.findIndex((qa) => qa._id === new_qa._id);
			const newQAList = [...prevQAList];
			newQAList[qaIndex] = new_qa;
			return newQAList;
		});
	}

	const insertQA = async (qa) => {
		const new_qa = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/qa_list`, 'POST', qa);
		setQAList((prevQAList) => [new_qa, ...prevQAList]);
	}

	const editQA = async (new_qa) => {
		setQAList((prevQAList) => {
			const qaIndex = prevQAList.findIndex((qa) => qa._id === new_qa._id);
			const newQAList = [...prevQAList];
			newQAList[qaIndex] = new_qa;
			return newQAList;
		});
		clearTimeout(userInputTimer);
		setUserInputTimer(setTimeout(() => updateQA(new_qa), 5000)); // Save after 5 seconds of inactivity
	}

	const deleteQA = async (qa) => {
		await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/qa_list/${qa._id}`, 'DELETE');
		setQAList((prevQAList) => {
			const qaIndex = prevQAList.findIndex((old_qa) => old_qa._id === qa._id);
			const newQAList = [...prevQAList];
			newQAList.splice(qaIndex, 1);
			return newQAList;
		});
	}

	const datetime_formatter = (time) => {
		if (time) {
			let date = new Date(time*1000);
			return date.getFullYear() + '-' +
			(date.getMonth()+1).toString().padStart(2, '0') + '-' +
			date.getDate().toString().padStart(2, '0') + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' + 
            date.getMinutes().toString().padStart(2, '0') + ':' +
            date.getSeconds().toString().padStart(2, '0');
		} else {
			return '-';
		}
	}

	return (<div>
		{showQAHintbar && (<div className='notification is-success is-light'>
			<button class="delete" onClick={() => setShowQAHintbar(false)}></button>
			Opt for Excellence: High-Quality Q&A Training Data Overpowers Quantity. Refine Your Bot's Knowledge Now!
			{/* TODO write a blog, and/or add a link */}
		</div>)}
		<div class="field">
			<label class="label">
				<div class="button ml-2 is-small is-primary"
					onClick={ async () => {
						await insertQA({
							question: '',
							answer: '',
						});
					}}
				>
					<i class="material-icons mr-1"
					>note_add</i> 
					Add
				</div>
				<div class={`button ml-2 is-small is-primary ${isGeneratingQA ? 'is-loading' : ''}`}
					onClick={ async () => {
						setIsGeneratingQA(true);
						const new_qa_pairs = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/generate_bot_qa_pairs`, 'POST');
						const updated_qa_pairs = [...new_qa_pairs, ...qaList];
						setQAList(updated_qa_pairs);
						setIsGeneratingQA(false);
					}}
				>
					<img src= {aiStarIcon} 
						style="width: 1rem; vertical-align: middle; margin-right: 0.5rem;" />
					Generate
				</div>
			</label>
		</div>
		<nav class="pagination is-centered is-small " pagination-color="#FFFFFF" role="navigation" aria-label="pagination">
			<ul class="pagination-list">
			{ currentPaginationPage > 2 &&
			<li>
				<a class="pagination-link" aria-label="Goto page 1"
					onClick={() => setCurrentPaginationPage(1)}
				>1</a>
			</li>
			}
			{ currentPaginationPage > 3 &&
			<li>
				<span class="pagination-ellipsis">&hellip;</span>
			</li>
			}
			{ currentPaginationPage > 1 &&
			<li>
				<a class="pagination-link" aria-label="Goto previous page"
					onClick={() => setCurrentPaginationPage(currentPaginationPage - 1)}
				>{currentPaginationPage - 1}</a>
			</li>
			}
			<li>
				<a class="pagination-link is-current" aria-label={currentPaginationPage} 
					aria-current="page">{currentPaginationPage}</a>
			</li>
			{ currentPaginationPage + 1 <= totalPaginationPages &&
			<li>
				<a class="pagination-link" aria-label="Goto next page"
					onClick={() => setCurrentPaginationPage(currentPaginationPage + 1)}
				>{currentPaginationPage + 1}</a>
			</li>
			}
			{ currentPaginationPage + 3 <= totalPaginationPages &&
			<li>
				<span class="pagination-ellipsis">&hellip;</span>
			</li>
			}
			{ currentPaginationPage + 2 <= totalPaginationPages &&
			<li>
				<a class="pagination-link" aria-label={totalPaginationPages}
					onClick={() => setCurrentPaginationPage(totalPaginationPages)}
				> {totalPaginationPages} </a>
			</li>
			}
			</ul>
		</nav>


		{ qaList.slice((currentPaginationPage - 1) * qaPerPage, currentPaginationPage * qaPerPage).map((qa, i) => (
			<div key={(currentPaginationPage - 1) * qaPerPage + i} class="mb-3">
			
			<div class="field is-horizontal">
				<div class="field-label">
					<label class="label">Q:</label>
				</div>
				<div class="" style="width: 100%;">
					<div class="control">
						<textarea class="textarea has-fixed-size" placeholder="Question"
							rows={1}
							value={qa.question}
							onInput={(e) => {
								const qaIndex = (currentPaginationPage - 1) * qaPerPage + i;
								qaList[qaIndex].question = e.target.value;
								editQA(qaList[qaIndex]);
							}}
						/>
					</div>
				</div>
			</div>
			<div class="field is-horizontal">
				<div class="field-label">
					<label class="label">A:</label>
				</div>
				<div class="" style="width: 100%;">
					<div class="control">
						<textarea class="textarea has-fixed-size" placeholder="Answer"
							rows={5}
							value={qa.answer}
							onInput={(e) => {
								const qaIndex = (currentPaginationPage - 1) * qaPerPage + i;
								qaList[qaIndex].answer = e.target.value;
								editQA(qaList[qaIndex]);
							}}
						/>
					</div>
				</div>
			</div>
			<div class="field is-horizontal mb-6">
				<div class="" style="width: 100%;">
					<div class="control ">
					Time Added: {datetime_formatter(qa.created_at)}
						<div class={`button ml-3 is-small is-danger is-light`}
							onClick={ async () => {
								await deleteQA(qa);
							}}
						><i class="material-icons mr-1"
						>delete</i>
						</div>
					</div>
				</div>
			</div>
			</div>
		))}
	</div>)
}

export default QAFineTuneTab;