import { h } from "preact";
import { useEffect, useState, useContext } from "preact/hooks";
import { chat_site_official_bot_id } from "../../utils";
import { sendAPIRequest } from "../../utils";

const BoProfilePage = ({ bot_id }) => {
  const [bot_profile, setBotProfile] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(async () => {
    try {
      const bot_profile = await sendAPIRequest(
        `/api/v1/bots/${bot_id}/profile_to_client`,
        "GET"
      );
      setBotProfile(bot_profile);

      const checkDataLayerAvailability = () => {
        if (window.siteChatDataLayer && window.siteChatDataLayer.length == 1) {
          window.siteChatDataLayer.push({
            event: "chatInit",
            botId: bot_id,
          });
        } else {
          setTimeout(checkDataLayerAvailability, 1000); // Check again after 1 second
        }
      };
      checkDataLayerAvailability();
    } catch (error) {
      setErrorMessage("Invalid bot id, please check your bot profile link.");
    }

    return () => {
      window.siteChatDataLayer.push({
        event: "chatInit",
        botId: chat_site_official_bot_id,
      });
    };
  }, []);

  return (
    <div class="container content" style={{ marginTop: "3rem" }}>
      <h1>Bot Profile Page</h1>
      <h2>Bot Name: {bot_profile.bot_name}</h2>
      {errorMessage && <h3>{errorMessage}</h3>}
    </div>
  );
};

export default BoProfilePage;
