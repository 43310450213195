# Set up for Google Sites

## Create a Site Chat account
Your first step is to set up a [Site Chat account](https://www.site-chat.com/console), unless you already have one. Skip to creating a property unless you want to create a separate account for this website and/or app. For example, you might want to create another account if this website and/or app belong(s) to a separate business.


## Add a Site Chat bot to your Google Sites

### Open Site Chat Console
1. Sign in to your [Site Chat account](https://www.site-chat.com/console).
2. In the left sidebar, click **Create a Bot** or an created bot.  Configure the bot if needed.  Any changes will be saved automatically and most of changes will take effect immediately.  You can always come back and change the bot configuration later.
3. Turn on the **Live Mode**.
4. Click the **Get code** button next to the Bot ID.  You will see a code block pop up.

   ![intergration_note](/assets/documentation/intergration/intergration_note.png)

### Add bot to all content hosted on a specific domain

1. On a computer, open a site in **new Google Sites**.
2. At the right, click Insert **> Embed**.
   - Alternatively, you can add an embed as an entire page:
     - Click **Pages** on the right.
     - Hover over **Add**.
     ![Add image](https://lh3.googleusercontent.com/-mzIvUEUvGVVFk_4JIESlSwoZNzOFNdaMoBcsxfJob8igl4wBwWYwW1gjCMq_ZiPuo0=h36)
    - Select Full page embed.
3. Click **Embed code**.
4. Add your code, then click **Next**.


```javascript
    <!-- Site Chat Script and Tag -->
    <script data-bot-id="C-your-bot-id" id="site-chat-script" 
        src="https://www.site-chat.com/lib/site-chat.js" type="module">
    </script>
```

5. Click **Insert**.
6. To publish your changes, click **Publish** at the top right.




### Next steps
After setting up your Site Chat bot, it's highly recommended to simulate user behavior and use Customer Insight Analytics tools for guidance in enhancing your bot. Regularly visit your [Site Chat console](https://www.site-chat.com/console) to monitor user interactions and observe how their appreciation for your bot evolves over time.