import { h, Component } from "preact";
import { useState, useEffect } from "preact/hooks";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("cookieConsentSeen") !== "true"
  );

  const handleAccept = () => {
    localStorage.setItem("cookieConsentSeen", "true");
    setShowBanner(false);
    // You can initialize non-essential cookies here.
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsentSeen", "false");
    setShowBanner(false);
    // Make sure to not set or to clear any non-essential cookies here.
  };

  return (
    showBanner && (
      <div
        className="notification is-primary is-fixed-bottom"
        style={{
          zIndex: 100,
          position: "fixed",
          width: "100%",
          bottom: 0,
        }}
      >
        <div className="container has-text-centered">
          We use cookies to enhance your experience. By clicking "Accept", you
          agree to our use of cookies.
          <a
            href="/cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="has-text-link"
          >
            Learn more about our cookie policy
          </a>
          .
          <div className="buttons button-group level-right">
            <button
              className="button is-success is-light is-small"
              onClick={handleAccept}
            >
              Accept
            </button>
            <button
              className="button is-danger is-light is-small"
              onClick={handleReject}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
