import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import ColorPicker from '../../components/color_picker';

const ThemeTab = ({ selectedBot, botThemeDefaultSettings, edit_selected_bot }) => {
    return (
    <div> 
        <div className='field is-horizontal'>
            <label class="field-label label">Icon Location</label>

            <div class="field-body control">
                <label class="radio">
                    <input type="radio" class="mr-2"
                        checked={selectedBot && selectedBot.icon_location === 'bottom_left'}
                        onClick={(e) => {
                            const icon_location = 'bottom_left'
                            window.siteChatDataLayer.push({
                                event: 'chatbotIconLocationUpdate',
                                icon_location: icon_location,
                            });
                            edit_selected_bot('icon_location', 'bottom_left')
                        }} />
                    Left
                </label>
                <label class="radio mr-3">
                    <input type="radio" class="mr-2"
                        checked={selectedBot && selectedBot.icon_location === 'bottom_right'}
                        onClick={(e) => {
                            const icon_location = 'bottom_right'
                            window.siteChatDataLayer.push({
                                event: 'chatbotIconLocationUpdate',
                                icon_location: icon_location,
                            });
                            edit_selected_bot('icon_location', 'bottom_right')
                        }} />
                    Right
                </label>
            </div>
        </div>
        <div className='field is-horizontal'>
            <div className='field-label label'>Colors</div>
            <div class="field-body control"></div>
        </div>
        
        <div> {(Object.keys(botThemeDefaultSettings).map((key) => (
            <div class="field is-horizontal">
                <div class="field-label is-small">
                    <label class="label">{botThemeDefaultSettings[key][0]}</label>
                </div>
                <div class="field-body">
                    <span style={{
                        width: '2rem',
                        height: '2rem',
                        background: selectedBot ? selectedBot['theme'][key] : botThemeDefaultSettings[key][1],
                        display: 'inline-block',
                        borderRadius: '4px',
                        marginRight: '0.5rem',
                        border: '1px solid',
                    }}></span>
                    <ColorPicker 
                        color = {selectedBot ? selectedBot['theme'][key] : botThemeDefaultSettings[key][1]}
                        setColor = {(color) => {
                            const selectedBotTheme = selectedBot.theme;
                            selectedBotTheme[key] = color;
                            edit_selected_bot('theme', selectedBotTheme);
                            window.siteChatDataLayer.push({
                                event: 'chatbotThemeUpdate',
                                theme: { ...selectedBotTheme }, // use a copy of the theme object to trigger the event
                            });
                        }}
                    />
                </div>										
            </div>
        )))}
        </div>
    </div>
)};

export default ThemeTab;