import { h } from 'preact';
import { useEffect, useState, useContext } from 'preact/hooks';
import GlobalStateContext from '../../context/GlobalStateContext';
import { sendAPIRequest, stripe } from '../../utils';
import shopify_change_plan_img from './Shopify_Change_Plan.png';
import UsageBar from '../../components/usage_bar';
import {
    Link
} from "@shopify/polaris";

const Profile = ({ profile, setProfile }) => {
    const [ profileUpdating, setProfileUpdating ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");

    const updateProfile = async () => {
        try {
            setProfileUpdating(true);
            const response = await sendAPIRequest(`/api/v1/users/${profile.id}/profile`, 'PUT', profile)
            setProfileUpdating(false);
        } catch (error) {
            setProfileUpdating(false);
            setErrorMessage(error.message);
        }
    };

    return (
    <div>
        <div className="title is-3 mt-3">User Profile</div>
        <hr className="navbar-divider mb-3" style={{ margin: '0' }} />					

        <label className="label">Account ID</label>
        <div className="field has-addons is-medium">
            <p className="control" style={{ width: '360px' }}>
                <input className="input is-small" type="text" value={profile.id} disabled />
            </p>            
            <p className="control">
                <a className="button is-small" onClick={() => navigator.clipboard.writeText(profile.id)}>
                    <i className="material-icons">content_copy</i> Copy
                </a>
            </p>
        </div>

        <div className='field'>
            <label className="label">Name</label>
            <p className="help"><div className="caption mt-1">The name associated with this account</div></p>
            <div className="control is-expanded">
                <input className="input is-small" type="text" 
                    value={profile.name} 
                    placeholder="Name"
                    onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                />
            </div>
        </div>

        <div className='field'>
            <label className="label">Email address</label>
            <p className="help"><div className="caption mt-1">The email address associated with this account</div></p>
            <div className="control is-expanded">
                <input className="input is-small" type="text" value={profile.email} disabled placeholder="Email address"/>
            </div>
        </div>

        {false && <div className='field'>
            <label className="label">Phone number</label>
            <p className="help"><div className="caption mt-1">The phone number associated with this account</div></p>
            <div className="control is-expanded">
                <input className="input is-small" type="text" value={profile.phone} disabled placeholder="Phone number"/>
            </div>
        </div>}

        <label className="label">Shopify Shop</label>
        <p className="help"><div className="caption mt-1">
            Shopify Store Name for Payment (if applicable, remove other payment methods first)
        </div></p>
        <div className="field has-addons is-medium">
            <p className="control" style={{ width: '360px' }}>
                <input className="input is-small" type="text" 
                    value={profile.shopify_shop} 
                    placeholder="your-shop-name"
                    onChange={(e) => setProfile({ ...profile, shopify_shop: e.target.value })}
                />
            </p>            
            <p className="control">.myshopify.com</p>
        </div>

        <div className='field'>
            <p className="control">
                <div className="button is-primary is-small"
                    onClick={updateProfile}
                    disabled={profileUpdating}
                >
                Save
                </div>
            </p>
        </div>



        {errorMessage && errorMessage.length > 0 && <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Error</p>
                    <button class="delete" aria-label="close"
                        onClick={() => setErrorMessage('')}
                    ></button>
                </header>
                <section class="modal-card-body">
                    {errorMessage}
                </section>
            </div>
        </div>}
    </div>
    )
}

const Usage = ({ user_id, profile }) => {
    const [ usage, setUsage ] = useState(null);
    const [ accountPlan, setAccountPlan ] = useState(null);
    const [ displayMode, setDisplayMode ] = useState('activity');

    const [ showPaymentChoiceModal, setShowPaymentChoiceModal ] = useState(false);

    useEffect(async () => {
        const response = await sendAPIRequest(`/api/v1/users/${user_id}/usage`, 'GET');
        setUsage(response);
        const accountPlanResponse = await sendAPIRequest(`/api/v1/users/${user_id}/account_plan`, 'GET');
        setAccountPlan(accountPlanResponse);
    }, []);

    const usageColors = {
        used: '#5ECEB3',
        available: '#33c9dc',
        overage: '#ff784e',
    };

    const planChangeButtonClicked = () => {
        if (window !== window.top) {
            // maybe in shopify embedded app
            const topUrl = document.referrer;
            console.log('top url: ', topUrl)
            if (topUrl.includes('shopify')) {
                // const targetUrl = 'https://shop-admin.site-chat.com';
                // const timestamp = new Date().getTime();
                // const newUrl = targetUrl.includes('?') ? `${targetUrl}&_=${timestamp}` : `${targetUrl}?_=${timestamp}`;
                // window.location.href = newUrl;

                setShowPaymentChoiceModal(true);
            }
            // other case, should not happen, stay in the current window
        } else {
            // Open Stripe Portal directly
            window.open(`${stripe['customer_portal_link']}?prefilled_email=${profile.email}`)
        }
    }

    return (
    <div>
        <div className="title is-3 mt-3">Usage</div>
        <hr className="navbar-divider mb-3"  />

        <div className="field is-grouped">
            <div className="level-item subtitle is-6">Account Plan</div>
            { accountPlan && (<div className="level-item subtitle is-6 ml-3"> {accountPlan.account_plan_type} </div>)}
            { accountPlan && (<div className="level-item button is-primary is-small ml-3" onClick={planChangeButtonClicked}>
                Manage Plan
            </div>)}
        </div>

        {usage && <div>

        <div className="field is-grouped">
            <div class="buttons has-addons">
            <button className={`button is-rounded is-small is-primary ${displayMode === 'activity' ? '' : 'is-outlined'}`} onClick={() => setDisplayMode('activity')}>Activity</button>                
                <button className={`button is-rounded is-small is-primary ${displayMode === 'cost' ? '' : 'is-outlined'}`} onClick={() => setDisplayMode('cost')}>Cost</button>
            </div>
            <div className="button is-white is-small ml-3">
                {JSON.stringify(usage.end_date)}
            </div> 
        </div>


        <div className='field is-grouped'>
            <div className="usage-legend" style={{borderRadius: '2px', height: '12px', marginRight: '4px', marginTop: '4px', width: '12px',
                backgroundColor: usageColors.used,
            }}></div>
            <span className="subtitle is-6 ml-1">Used</span>
            <div className="usage-legend ml-3" style={{borderRadius: '2px', height: '12px', marginRight: '4px', marginTop: '4px', width: '12px',
                backgroundColor: usageColors.available,
            }}></div>
            <div className="subtitle is-6 ml-1">Available</div>
            <div className="usage-legend ml-3" style={{borderRadius: '2px', height: '12px', marginRight: '4px', marginTop: '4px', width: '12px',
                backgroundColor: usageColors.overage,
            }}></div>
            <div className="subtitle is-6 ml-1">Extra</div>
        </div>

        <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">Monthly Credit Grants</label>
            <UsageBar usage_data={usage.monthly_credit_grants_usages} width="300px" /> 
            <label className='control ml-3'>{usage.monthly_credit_grants_usages.info}</label>
        </div>

        <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">PAYG Credit</label>
            <UsageBar usage_data={usage.payg_credit_usages} width="300px" /> 
            <label className='control ml-3'>{usage.payg_credit_usages.info}</label>
            <div className='button is-primary is-small'>Top up</div>
        </div>

        <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">Chatbots</label>
            <UsageBar usage_data={usage.bot_usages} width="300px" />
            <label className='control ml-3'>{usage.bot_usages.info}</label>
        </div>

        <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">Messages</label>
            <UsageBar usage_data={usage.message_usages[displayMode]} width="300px" />
            <label className='control ml-3'>{usage.message_usages[displayMode].info}</label>
        </div>

        <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">QA Fine Tunes</label>
            <UsageBar usage_data={usage.qa_fine_tune_usages} width="300px" />
            <label className='control ml-3'>{usage.qa_fine_tune_usages.info}</label>
        </div>

        <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">Knowledge Sources</label>
            <UsageBar usage_data={usage.knowledge_link_usages} width="300px" />
            <label className='control ml-3'>{usage.knowledge_link_usages.info}</label>
        </div>

        {/* <div className="field is-grouped">
            <label className="label mr-3" style="width: 120px">Customer Insight Reports</label>
            <UsageBar usage_data={usage.customer_insight_reports_usages[displayMode]} width="300px" />
            <label className='control ml-3'>{usage.customer_insight_reports_usages[displayMode].info}</label>
        </div> */}

        </div>}

        {showPaymentChoiceModal && <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card
                    -title">Choose your plan</p>
                    <button class="delete" aria-label="close"
                        onClick={() => setShowPaymentChoiceModal(false)}
                    ></button>
                </header>
                <section class="modal-card-body">
                    <a href={`${stripe['customer_portal_link']}?prefilled_email=${profile.email}`} target='_blank'>Stripe Portal</a>
                    <div style={{fontWeight: 'bold', padding: '1rem 0'}}>Shopify Admin (Recommended)</div>
                    <img src={shopify_change_plan_img} alt="shopify_change_plan_instruction_image" style={{fontWeight: 'bold', padding: '1rem'}} />
                </section>
            </div>
        </div>}
    </div>
    )
}

const Billing = ({ user_id }) => {
    const [ billing, setBilling ] = useState({});
    const [ billingUpdating, setBillingUpdating ] = useState(false);
    return (
    <div>
        <div className="title is-3 mt-3">Billing</div>
        <hr className="navbar-divider mb-3" style={{ margin: '0' }} />
    </div>
    )
}

const AccountPage = () => {
    const { user_id, logout } = useContext(GlobalStateContext);
    const [ profile, setProfile ] = useState({});
    const [ activeTab, setActiveTab ] = useState('Profile');

    const [ showAccountMenu, setShowAccountMenu ] = useState(false);

    useEffect(async () => {
        const response = await sendAPIRequest(`/api/v1/users/${user_id}/profile`, 'GET');
        setProfile(response);
    }, []);

    return (
        <div className="columns is-mobile" 
            style={{
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                height: '100vh',
            }}
        >
            <aside className={`menu column is-2`}
                style={{
                    borderRight: '2px solid #ECECEC',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <ul className="menu-list">
                    {['Profile', 'Usage'].map((tab) => (
                    // {['Profile', 'Usage', 'Billing'].map((tab) => (
                        <li><a 
                            onClick={() => setActiveTab(tab)} 
                            className={activeTab === tab ? 'navbar-item has-text-primary' : ''}
                            style={activeTab === tab  ? {
                                    background: '#EFFFFC',
                                    fontWeight: 'bold',
                                } : {}
                            }
                        >{tab}</a></li>
                    ))}
                </ul>
                <hr className="navbar-divider" style={{ margin: '0' }} />					
                <ul className="menu-list">
                    <li><a href="/console">My Bots</a></li>
                </ul>
                <div className="is-flex-grow-1"></div>
                

                <div className={`dropdown is-up ${showAccountMenu ? 'is-active' : ''}`}
                    style={{ cursor: 'pointer' }}
                >
                    <div class="dropdown-trigger"
                        onClick={() => setShowAccountMenu(!showAccountMenu)}
                    >
                        <span class="subtitle is-6">{profile.name || profile.email || 'Account'}</span>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu7" role="menu">
                        <div class="dropdown-content">
                        <div class="dropdown-item">
                            <ul className="menu-list">
                                <li>
                                    <a href="https://forms.gle/YBgM2x7WWzqHQ1AF6" target='_blank'>Feedback</a>
                                </li>
                                <li><a onClick={() => {
                                    logout();
                                    window.location.href = '/';
                                }}>Log out</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>

            </aside>

            <div className={`menu column is-10`}
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                }}
            >
                { activeTab === 'Profile' && <Profile profile={profile} setProfile={setProfile} /> }
                { activeTab === 'Usage' && <Usage user_id={user_id} profile={profile} /> }
                { activeTab === 'Billing' && <Billing user_id={user_id} /> }
                
            </div>
        </div>
)};

export default AccountPage;
