import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import './style.css';

const colors = ['#5ECEB3', '#33c9dc', '#ff784e']; // Predefined colors: used, available, extra

const UsageBar = ({ usage_data, width }) => {
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [ items, setItems ] = useState([]);

  useEffect(() => {
    if (!usage_data) return;
    setItems([
      { name: 'Used', value: usage_data.used, color: colors[0] },
      { name: 'Available', value: usage_data.available, color: colors[1] },
      { name: 'Extra', value: usage_data.extra, color: colors[2] },
    ]);
  }, [usage_data]);

  const totalValue = items.reduce((total, item) => total + item.value, 0);

  const showTooltip = (content) => {
    setTooltipContent(content);
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="progress-bar-container" style={{ width, position: 'relative' }}>
      {items.map((item, index) => (
        <div
          key={index}
          className="progress-segment"
          style={{
            width: `${((totalValue == 0 && item.name == 'Used')? 100 : (item.value / totalValue)) * 100}%`,
            backgroundColor: item.color ? item.color : colors[index % colors.length],
          }}
          onMouseOver={() => showTooltip(`${item.name}: ${item.value.toFixed(2)}`)}
          onMouseOut={hideTooltip}
        />
      ))}
      {tooltipVisible && (
        <div className="progress-bar-tooltip" style={{ position: 'absolute', bottom: '100%' }}>
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default UsageBar;
