import { h } from "preact";
import { Link } from "preact-router/match";

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>© 2024 Site Chat. All rights reserved.</p>
      {/* Social media links */}
      {/* Additional footer links */}
    </div>
  </footer>
);

export default Footer;
