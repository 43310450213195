import { h, render } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";
import Router from "preact-router";

import GlobalStateProvider from "./context/GlobalStateProvider";
import GlobalStateContext from "./context/GlobalStateContext";

import Header from "./components/Header";
import Footer from "./components/Footer";
import CookieConsent from "./components/cookie_consent";

import Console from "./pages/Console";
import Home from "./pages/Home";
import AccountPage from "./pages/Account";
import BotProfilePage from "./pages/BotProfile";

import ResetPasswordPage from "./pages/reset_password";

import CookiePolicyPage from "./pages/cookie_policy";
import PrivacyPolicyPage from "./pages/privacy_policy";
import TermsOfUsePage from "./pages/terms_of_use";
import SupportedCountriesPage from "./pages/supported_countries";

import DocumentationPage from "./pages/documentation";

import Editor from "./pages/Editor";

import "./index.css";

const ProtectedRoute = ({ component: Component, ...props }) => {
  const { isUserLoggedIn, triggerLoginModalOn } =
    useContext(GlobalStateContext);

  if (!isUserLoggedIn) {
    triggerLoginModalOn(); // Show login modal
    return null; // Do not render the component until logged in
  }
  return <Component {...props} />;
};

const App = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    const hideHeaderOnRoutes = ["/console"];
    const hideFooterOnRoutes = [
      "/console",
      "/cookie-policy",
      "/privacy-policy",
      "terms-of-use",
    ];
    const shouldHideHeader = hideHeaderOnRoutes.some((route) =>
      window.location.pathname.includes(route)
    );
    const shouldHideFooter = hideFooterOnRoutes.some((route) =>
      window.location.pathname.includes(route)
    );
    setShowHeader(!shouldHideHeader);
    setShowFooter(!shouldHideFooter);
  }, [window.location.pathname]);

  return (
    <div>
      <GlobalStateProvider>
        {showHeader && <Header />}
        <Router>
          <Home path="/" />
          <ProtectedRoute path="/console" component={Console} />
          <ProtectedRoute path="/console/account" component={AccountPage} />

          <ResetPasswordPage path="/reset-password" />

          <DocumentationPage path="/docs/:doc_category/:doc_name?" />
          <DocumentationPage path="/docs/:doc_category?" />
          <DocumentationPage
            path="/docs"
            doc_category="integrations"
            doc_name="website"
          />

          <BotProfilePage path="/b/:bot_id" />

          <CookiePolicyPage path="/cookie-policy" />
          <PrivacyPolicyPage path="/privacy-policy" />
          <TermsOfUsePage path="/terms-of-use" />
          <SupportedCountriesPage path="/supported-countries" />

          <Editor path="/editor" />
        </Router>
        {showFooter && <Footer />}
        <CookieConsent />
      </GlobalStateProvider>
    </div>
  );
};

render(<App />, document.getElementById("app"));
