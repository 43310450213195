import { h } from 'preact';

const PrivacyPolicyPage = () => {
    return (
        <div className="container">
            <section className="section">
                <h1 className="title">Privacy Policy</h1>
                <p className="subtitle">Updated: December 01, 2023</p>
                <div className="content">

                    <h2>Introduction</h2>
                    <p>
                        Welcome to Site Chat, a service offered by Ziida ("THE COMPANY", "we", "us", or "our"). At Ziida, we recognize the importance of your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you interact with our website, applications, and other related services (collectively referred to as "Site Chat Services"). This policy applies to all users accessing Site Chat Services from the UK, EU, US, and other regions. By using Site Chat Services, you consent to the practices described in this Privacy Policy. If you disagree with any terms provided in this policy, please discontinue the use of all Site Chat Services.
                    </p>

                    <h2>1. Data Collection</h2>
                    <p>
                        When you engage with Site Chat, various types of information are collected from and about you:
                    </p>

                    <h3>Direct Information:</h3>
                    <ul>
                        <li><strong>Registration Data:</strong> When you sign up for Site Chat, you provide us with details like your username, password, email address, and possibly additional profile information.</li>
                        <li><strong>User Interactions:</strong> This encompasses all forms of interactions you have with our IT services, such as queries posed, feedback provided, and other forms of engagement across our platforms, including our website, mobile app, chatbots, and other digital interfaces.</li>
                        <li><strong>Correspondence:</strong> If you contact us directly, whether it's for support, feedback, or other reasons, we collect and store that communication.</li>
                    </ul>

                    <h3>Automated Collection:</h3>
                    <p>As you navigate and use Site Chat Services, certain data is collected automatically:</p>
                    <ul>
                        <li><strong>Browsing Metrics:</strong> This includes details like the pages you visit, the duration of your visits, and the sequence of your navigation.</li>
                        <li><strong>Device and Access Information:</strong> Details about the device you're using to access Site Chat, its operating system, the browser type, your IP address, and other related technical data.</li>
                        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies — small data files stored on your device — and other tracking technologies to personalize your experience and collect data about your interaction with Site Chat.</li>
                    </ul>

                    <h3 className="subtitle is-4">Third-party Data Collection:</h3>
                    <p>Some embedded or linked services on Site Chat, such as payment gateways or social media buttons, may also collect information about you. While we strive to work with reputable partners, their data practices are governed by their respective privacy policies.</p>


                    <h2 className="subtitle">2. Use of Data</h2>
                    <p className="content">
                        Your information is invaluable to us, primarily because it helps enhance the quality of Site Chat Services. Here's a breakdown of how we use the collected data:
                    </p>

                    <ul>
                        <li><strong>Service Provision:</strong> We use your data to register your account, provide chatbot services, and ensure seamless user interactions on Site Chat.</li>
                        <li><strong>Personalization:</strong> By understanding user behaviors and preferences, we can tailor Site Chat's features and content to offer a more personalized experience.</li>
                        <li><strong>Communication:</strong> Your contact information allows us to send you updates, notifications, newsletters, and respond to your queries or feedback.</li>
                        <li><strong>Service Improvement:</strong> By analyzing usage patterns, feedback, and other metrics, we continuously refine and enhance Site Chat to better serve our user base.</li>
                        <li><strong>Security:</strong> We monitor activity to detect and prevent potential threats, ensuring the safety and integrity of our platform.</li>
                        <li><strong>Legal Compliance:</strong> In certain situations, we might need to process or share your data to comply with regulatory requirements or legal obligations.</li>
                        <li><strong>Research and Development:</strong> With a focus on innovation, we occasionally use aggregated and anonymized data for research, aiming to develop new features or improve existing ones.</li>
                    </ul>


                    <h2 className="subtitle">3. Sharing Your Data</h2>
                    <p className="content">
                        We value your trust and ensure that your personal information is shared with discretion. Here are the circumstances under which we might disclose your data:
                    </p>

                    <ul>
                        <li><strong>Service Providers and Partners:</strong> We collaborate with third-party service providers to facilitate certain aspects of Site Chat, such as hosting, payment processing, and analytics. These entities may access your data only to perform tasks on our behalf and are bound by confidentiality agreements.</li>
                        <li><strong>Legal and Regulatory Bodies:</strong> If mandated by law, or in response to a legal process, we might need to disclose your information. This includes situations where we need to protect our rights, safeguard our users, or address fraud or security concerns.</li>
                        <li><strong>Affiliates:</strong> Entities associated with Ziida, who operate under this Privacy Policy or similar privacy standards, may access your data for collaborative purposes or to offer combined services.</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, reorganization, or sale of assets, user information might be one of the transferred assets. In such cases, you'll be notified before your data becomes subject to a different privacy policy.</li>
                        <li><strong>Consent-Based Sharing:</strong> For any other type of data sharing not mentioned here, we'll seek your explicit consent.</li>
                    </ul>
                    <p className="content">
                        It's important to note that we never sell your personal data to third parties.
                    </p>


                    <h2 className="subtitle">4. Your Rights</h2>
                    <p className="content">
                        As a user of Site Chat, you have several rights concerning your data, especially if you are accessing our services from the UK, the EU, or the US:
                    </p>

                    <ul>
                        <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
                        <li><strong>Correction:</strong> If you believe any of the information we have is inaccurate, you can request corrections.</li>
                        <li><strong>Deletion:</strong> You can ask us to delete your personal data, subject to certain exceptions.</li>
                        <li><strong>Data Portability:</strong> In certain conditions, you can ask for a copy of your data in a structured, machine-readable format, or request that we transfer it directly to another company.</li>
                        <li><strong>Restriction:</strong> You can request a restriction on the processing of your data, such as when you contest its accuracy.</li>
                        <li><strong>Objection:</strong> You can object to us processing your data for particular purposes, including direct marketing.</li>
                        <li><strong>Withdraw Consent:</strong> If our processing is based on your consent, you can withdraw it at any time, though this won't affect the legality of previous processing based on consent.</li>
                        <li><strong>Complain:</strong> If you believe your data protection rights have been breached, you have the right to lodge a complaint with the UK's data protection authority or your local EU data protection regulator.</li>
                    </ul>

                    <p className="content">
                        To exercise any of these rights or for further clarification, reach out to us at support@site-chat.com.
                    </p>

                    <h2 className="subtitle">5. Data Security</h2>
                    <p className="content">
                        Ensuring the safety of your data is paramount at Site Chat. Here's how we safeguard it:
                    </p>

                    <ul>
                        <li><strong>Security Measures:</strong> We employ state-of-the-art technical and organizational security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes using secure server environments, encryption techniques, and regular security assessments.</li>
                        <li><strong>Employee Training:</strong> Our staff and collaborators undergo regular training to ensure they are up-to-date with the latest security protocols and data protection practices.</li>
                        <li><strong>Breach Notification:</strong> In the unlikely event of a data breach that compromises your personal data, we will promptly notify affected users and relevant authorities, as required by law.</li>
                        <li><strong>Limitation of Liability:</strong> While we strive for maximum security, no digital platform is completely invulnerable. We recommend you use a strong, unique password for your Site Chat account and refrain from sharing sensitive information unnecessarily.</li>
                    </ul>


                    <h2 className="subtitle">6. Data Retention</h2>
                    <p className="content">
                        Your data is stored based on its utility and legal requirements. Here's our approach:
                    </p>

                    <ul>
                        <li><strong>Retention Duration:</strong> We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is mandated by law or for tax, legal, or audit reasons.</li>
                        <li><strong>Account Deletion:</strong> If you choose to delete your Site Chat account, we will remove your personal data from our active databases. Some information might remain in our backups and archives for a longer period but will be securely stored and isolated from regular processing.</li>
                        <li><strong>Automated Decisions:</strong> Data used to make automated decisions, such as chatbot interactions, is stored for a limited time to improve the service but is regularly purged to ensure privacy.</li>
                        <li><strong>Anonymization:</strong> In some cases, we may anonymize your data for research or statistical purposes, in which case we may use this information indefinitely without further notice.</li>
                    </ul>


                    <h2 className="subtitle">7. International Data Transfers</h2>
                    <p className="content">
                        Site Chat is accessible globally, but our primary data centers are located in the UK. This means your data may cross international borders. Here's how we handle such transfers:
                    </p>

                    <ul>
                        <li><strong>Data Storage:</strong> While our main operations are in the UK, we use cloud services and other technologies that may store data in different countries, including the US, the UK, and nations within the EU.</li>
                        <li><strong>Compliance with Regulations:</strong> We ensure that any data transfers to countries outside of the EU are compliant with relevant data protection regulations, including the General Data Protection Regulation (GDPR).</li>
                        <li><strong>Transfer Mechanisms:</strong> For data transfers outside the EU, we rely on recognized legal frameworks such as the EU-US Privacy Shield or standard contractual clauses approved by the European Commission.</li>
                        <li><strong>Third-party Processors:</strong> Our third-party service providers may also transfer data internationally. We ensure they commit to data protection standards equivalent to ours.</li>
                    </ul>

                    <h2 className="subtitle">8. Children's Data</h2>
                    <p className="content">
                        At Site Chat, we are committed to safeguarding the privacy of younger users:
                    </p>

                    <ul>
                        <li><strong>Age Restrictions:</strong> Our services are designed for users aged 13 and above. We do not knowingly collect or solicit personal data from anyone under the age of 13.</li>
                        <li><strong>Parental Control:</strong> If you are a parent or guardian and believe your child has provided us with personal data without your consent, please contact us immediately at legal@chatbotservices.com. We will take steps to promptly delete such information.</li>
                        <li><strong>User Responsibility:</strong> Users aged between 13 and 18 must have parental or guardian consent to use Site Chat Services and should not provide us with any personal data without such consent.</li>
                    </ul>


                    <h2 className="subtitle">9. Links to Other Websites</h2>
                    <p className="content">
                        Site Chat may contain links or integrations with other platforms. Here's what you need to know:
                    </p>

                    <ul>
                        <li><strong>Third-party Sites:</strong> Site Chat might include links to external websites, apps, or services not owned or controlled by Ziida. This could range from payment processors to social media platforms.</li>
                        <li><strong>Privacy Policies:</strong> These third-party sites operate under their own privacy policies. We encourage you to review these policies if you choose to visit or use such sites. We hold no responsibility or liability for their content or data practices.</li>
                        <li><strong>User Discretion:</strong> While we aim to partner with reputable entities, your interactions with third-party sites are at your own discretion and risk. Always be cautious when sharing personal information outside Site Chat.</li>
                    </ul>

                    <h2 className="subtitle">10. Modifications to this Policy</h2>
                    <p className="content">
                        Site Chat's growth and the ever-evolving nature of data protection mean this Privacy Policy might undergo changes over time:
                    </p>

                    <ul>
                        <li><strong>Updates & Changes:</strong> We reserve the right to modify this Privacy Policy periodically. Any modifications will be effective upon the posting of the revised policy on this site.</li>
                        <li><strong>Notification:</strong> While we will strive to notify our users of significant changes, it's essential for you to review this policy regularly to stay informed.</li>
                        <li><strong>Acceptance:</strong> Continuing to use Site Chat Services after changes to this policy signifies your acceptance of those changes. If you disagree with any updates, it's best to discontinue using Site Chat Services.</li>
                    </ul>

                    <h2 className="subtitle">11. Contacting Us</h2>
                    <p className="content">
                        Your feedback, questions, and concerns about this Privacy Policy or Site Chat Services are essential to us:
                    </p>

                    <ul>
                        <li><strong>General Queries:</strong> For general questions or feedback about Site Chat Services, please reach out to <a href="mailto:support@site-chat.com">support@site-chat.com</a>.</li>
                        <li><strong>Mailing Address:</strong>
                            <address>
                                Ziida<br />
                                Unit8, Dock Offices<br />
                                Surrey Quays Road<br />
                                London<br />
                                United Kingdom<br />
                                SE16 2XU<br />
                            </address></li>
                        <li><strong>Online:</strong> You can also use the contact form available on our website to get in touch with us.</li>
                    </ul>

                    <p className="content">
                        Your trust is paramount, and we're here to assist with any concerns or clarifications you might need.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default PrivacyPolicyPage;
