# Shopify: Set up your Site Chat bot

## Before you begin

You can integrate your Shopify account with Site Chat without additional cost, including our free forever Developer Plan. To set up the integration, you’ll need a valid [Site Chat sign-in](https://www.site-chat.com/console), a Site Chat Bot ID, and an active [Shopify account](https://accounts.shopify.com/).

## Add a Site Chat bot to your store

### Method 1: No Code Setup (Recommended)

1. Install our **Site Chat: Chat GPT Sales Rep** App

<img width="300px" src="/assets/documentation/intergration/shopify/shopify_app.png"/>

2. Sign in to your [Site Chat account](https://www.site-chat.com/console).
3. In the left sidebar, click **Create a Bot** or an created bot.  Configure the bot if needed.  Any changes will be saved automatically and most of changes will take effect immediately.  *You can always come back and change the bot configuration later.*



4. Turn on the **Live Mode**.

5. Click the **Copy** button, which will copy the Bot ID to your clipboard.

   ![bot_id_copy](/assets/documentation/intergration/bot_id_copy.png)

6. In a separate window, sign in to your [Shopify account](https://accounts.shopify.com/).

7. In the **App embeds** windows ①, click the arrow ②, open the settings section, enable the app and enter your bot id copied from **Step 4** ③.

   ![shopify_4.png](/assets/documentation/intergration/shopify/shopify_4.png)

Congratulations on successfully integrating your AI Chat Assistant on Shopify. Your bot is now active and will be available to assist your customers 24/7 in your online store!


### Method 2: Little Code Setup

1. Sign in to your [Site Chat account](https://www.site-chat.com/console).
2. In the left sidebar, click **Create a Bot** or an created bot.  Configure the bot if needed.  Any changes will be saved automatically and most of changes will take effect immediately.  *You can always come back and change the bot configuration later.*
3. Turn on the **Live Mode**.
4. Click the **Get code** button next to the Bot ID.  You will see a code block pop up.

   ![intergration_note](/assets/documentation/intergration/intergration_note.png)

5. In a separate window, sign in to your [Shopify account](https://accounts.shopify.com/).

6. Within Shopify, select the **Online Store > Themes** option from the panel on the left and then select the **Customize** button.

   ![shopify_1.png](/assets/documentation/intergration/shopify/shopify_1.png)

7. In the **Footer** section, click **Add section**, then click **Custom Liquid**.

   ![shopify_2.png](/assets/documentation/intergration/shopify/shopify_2.png)

8. In the **Liquid code** block, paste the `<script>` bolck copied from **Step 5**.  The code looks like the followings

9. Click the **Save** button on the top right.

   ![shopify_3.png](/assets/documentation/intergration/shopify/shopify_3.png)

Congratulations on successfully integrating your AI Chat Assistant on Shopify. Your bot is now active and will be available to assist your customers 24/7 in your online store!
