import { h } from 'preact';
// import React from '@preact/compat'; // Using the React alias

import { useState, useCallback, useEffect } from 'preact/hooks';
import { sendAPIRequest } from '../../utils';
import Cropper from 'react-easy-crop';
// import 'react-image-crop/dist/ReactCrop.css';


const ImageCropper = ({ imageSrc, onCropped }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const getCroppedImg = (imageSrc, pixelCrop) =>{
        const image = new Image();
        image.src = imageSrc;
      
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
      
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
      
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
      
        return new Promise((resolve) => {
          canvas.toBlob((blob) => {
            // Canvas is empty
            if (blob === null) return;
            resolve(URL.createObjectURL(blob));
          }, 'image/jpeg');
        });
    }

    const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        if (croppedImage) {
            onCropped(croppedImage);
        }
    }, [imageSrc, onCropped]);

    return (
        imageSrc && (
            <div className="image-cropper">
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={true}
                    style={{ containerStyle: { backgroundColor: '#fff' } }}
                />
            </div>
        )
    );
};

const DescriptionTab = ({ bot_id, imageSrc, setImageSrc, onCropped, editAvatarModalLoading, setEditAvatarModalLoading }) => {
    const [description, setDescription] = useState('');
    const handleGenerateImage = async () => {
        setEditAvatarModalLoading(true);
        const avatar = await sendAPIRequest(`/api/v1/bots/${bot_id}/generate_bot_avatar`, 'POST', { description });
        if (avatar && avatar.length > 0) {
            setImageSrc(avatar);
        }
        setEditAvatarModalLoading(false);
    }
        
    return (
        <div>
            <textarea class="textarea is-primary" 
                placeholder="Enter an image description.  For example: (1) A cute bot, or (2) A minimalist digital avatar on a light blue background. It has a gradient blue-white round face, large gleaming eyes, a dynamic line for a mouth, and a small antenna on the left."
                value={description}
                onInput={(e) => setDescription(e.target.value)}
                disabled={editAvatarModalLoading}
                rows="10"                
            />
            <button class='button is-fullwidth is-primary'
                style={{ marginTop: '1rem' }}
                disabled={editAvatarModalLoading || description.length < 10}
                onClick={handleGenerateImage}
            >
                {editAvatarModalLoading && <i class='material-icons spin'>sync</i>}
                Generate Image
            </button>
            <ImageCropper 
                imageSrc={imageSrc} 
                onCropped={onCropped}
            />
        </div>
    );
};


const GalleryTab = ({ imageSrc, setImageSrc, onCropped, editAvatarModalLoading, setEditAvatarModalLoading }) => {
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        const fetchGalleryImages = async () => {
            const galleryImages = await sendAPIRequest(`/api/users/${localStorage.getItem('user_id')}/gallery`, 'GET');
            setGalleryImages(galleryImages);
        }
        fetchGalleryImages();
    }, []);
    return (
        <div>
            {/* adding a gallery loading icon */}
            <div class="columns is-multiline">
                {galleryImages.length === 0 && <div class="column is-full" style={{ textAlign: 'center' }}>
                    <i class="material-icons spin md-48" style={{color: 'black'}}>sync</i>
                </div>}
            </div>

            <div class="columns is-multiline">
                {galleryImages.map((image) => (
                    <div class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop">
                        <img src={image.content_b64} alt="Gallery Image"  />
                        <button class="button is-fullwidth is-primary" 
                            onClick={() => {
                                setImageSrc(image.content_b64);
                            }}>
                            Select
                        </button>
                    </div>
                ))}
            </div>

            <ImageCropper 
                imageSrc={imageSrc} 
                onCropped={onCropped}
            />
        </div>
    );
};

const UploadTab = ({ imageSrc, setImageSrc, onCropped, editAvatarModalLoading, setEditAvatarModalLoading}) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            }
            reader.readAsDataURL(file);
        }
        // Reset the input so that the same file can be uploaded again
        event.target.value = null;
    };

    return (
        <div>
            <div class="field">
                <div class="file" style="width: 100%;">
                    <label class="file-label button is-primary" style="width: 100%; justify-content: center;">
                        <input class="file-input" style="display: none;" type="file" accept="image/*" onChange={handleFileChange} />
                        <i class="material-icons md-48">image</i> Choose an image
                    </label>
                </div>
            </div>
            <ImageCropper 
                imageSrc={imageSrc} 
                onCropped={onCropped}
            />
        </div>
    );
};



const AvatarEditor = ({
    bot_id,
    avatarEditorVisible,
    avatarUpdateHandler,
    closeAvatarEditorHandler
}) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [editAvatarModalLoading, setEditAvatarModalLoading] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleCroppedImage = (croppedImage) => {
        setCroppedImage(croppedImage);
    };
    
    return (
		<div class={`modal ${avatarEditorVisible ? 'is-active' : ''}`}>
				
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Avatar Editor</p>
                </header>
                <section class="modal-card-body" style="height: calc(60vh)">
                    <div class="tabs">
                        <ul>
                            <li class={activeTab === 1 ? 'is-active' : ''} onClick={() => setActiveTab(1)}><a>Description</a></li>
                            <li class={activeTab === 3 ? 'is-active' : ''} onClick={() => setActiveTab(3)}><a>Upload</a></li>
                        </ul>
                    </div>
                    
                    {activeTab === 1 && <DescriptionTab bot_id={bot_id} imageSrc={imageSrc} setImageSrc={setImageSrc} onCropped={handleCroppedImage} editAvatarModalLoading={editAvatarModalLoading} setEditAvatarModalLoading={setEditAvatarModalLoading}/>}
                    {activeTab === 3 && <UploadTab imageSrc={imageSrc} setImageSrc={setImageSrc} onCropped={handleCroppedImage}  editAvatarModalLoading={editAvatarModalLoading}/>}
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-fullwidth" 
                        disabled={editAvatarModalLoading}
                        onClick={() => {
                            closeAvatarEditorHandler();
                            setCroppedImage(null);
                            setImageSrc(null);
                        }}>
                        <i class="material-icons md-48">cancel</i>Cancel
                    </button>
                    <button
                        class='button is-fullwidth action_button'
                        disabled={editAvatarModalLoading || !croppedImage}
                        onClick={() => {
                            setEditAvatarModalLoading(true);
                            avatarUpdateHandler(croppedImage);
                            setCroppedImage(null);
                            setImageSrc(null);
                            setEditAvatarModalLoading(false);
                        }}
                        >
                            {editAvatarModalLoading && <i class='material-icons spin'>sync</i>}
                            <i class="material-icons md-48">save_as</i>
                            Confirm
                    </button>						
                </footer>
            </div>
        </div>
    );
};

export default AvatarEditor;
