# Set up for HubSpot

## Create a Site Chat account
Your first step is to set up a [Site Chat account](https://www.site-chat.com/console), unless you already have one. Skip to creating a property unless you want to create a separate account for this website and/or app. For example, you might want to create another account if this website and/or app belong(s) to a separate business.


## Add a Site Chat bot to your HubSpot site

### Open Site Chat Console
1. Sign in to your [Site Chat account](https://www.site-chat.com/console).
2. In the left sidebar, click **Create a Bot** or an created bot.  Configure the bot if needed.  Any changes will be saved automatically and most of changes will take effect immediately.  *You can always come back and change the bot configuration later.*
3. Turn on the **Live Mode**.
4. Click the **Get code** button next to the Bot ID.  You will see a code block pop up.

   ![intergration_note](/assets/documentation/intergration/intergration_note.png)

### Add bot to all content hosted on a specific domain

In your HubSpot account, navigate to the settings icon located in the main navigation bar.
Follow these steps to modify your website pages:

1. On the left sidebar menu, go to **Website > Pages**.
2. At the top left, open the dropdown menu titled **Choose a domain to edit its settings** and select your desired domain. To apply changes to all HubSpot-hosted content, choose **Default settings for all domains**.
3. For domain-specific modifications, select **Override default settings** in either the **Site Header HTML** or **Site Footer HTML** section. Note that doing so will prevent the default settings from applying to the content of this domain.

```javascript
    <!-- Site Chat Script and Tag -->
    <script data-bot-id="C-your-bot-id" id="site-chat-script" 
        src="https://www.site-chat.com/lib/site-chat.js" type="module">
    </script>
```

4. To revert the header or footer HTML of a specific domain back to the **Default settings for all domains**, click **Apply default settings**.
5. Insert your code snippets where needed.
6. Finally, click **Save** in the bottom left to implement your changes.






### Next steps
After setting up your Site Chat bot, it's highly recommended to simulate user behavior and use Customer Insight Analytics tools for guidance in enhancing your bot. Regularly visit your [Site Chat console](https://www.site-chat.com/console) to monitor user interactions and observe how their appreciation for your bot evolves over time.