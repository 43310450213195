import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { sendAPIRequest } from '../../utils';
import style from './KnowledgeLinks.css';

const LinkPreviewCard = ({ link }) => {
	return (<div class="related_link"
		style={{
			maxHeight: '100px',
		}}
		onClick={() => link.url && window.open(link.url, '_blank')}
	>
		<div class="card">
			<div class="card-content">
				<div class="media">
					{link.thumbnail && <div class="media-left">
						<figure class="image is-48x48">
							<img src={link.thumbnail} alt="link image"/>
						</figure>
					</div>}
					{link.title && <div class="media-content">
						<p class="title is-6">{link.title}</p>
					</div>}
				</div>
			</div>
		</div>
	</div>);
}

const LinkEditorModal = ({ link, updataLink, closeLinkEditorModal }) => {
	const [newLink, setNewLink] = useState(link);
	const [errorMessage, setErrorMessage] = useState('');

	return (<div class="modal is-active">
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">Edit Link</p>
				<button class="delete" aria-label="close"
					onClick={closeLinkEditorModal}
				></button>
			</header>
			<section class="modal-card-body">
				{/* card preview */}
				<LinkPreviewCard link={newLink} />
				{/* url */}
				<div class="field">
					<label class="label">URL</label>
					<div class="control">
						<input class="input" type="text" placeholder="URL"
							value={newLink.url}
							onChange={(e) => setNewLink({...newLink, url: e.target.value.trim()})}
						/>
					</div>
				</div>
				{/* thumbnail */}
				<div class="field">
					<label class="label">Thumbnail URL</label>
					<div class="control">
						<input class="input" type="text" placeholder="Thumbnail"
							value={newLink.thumbnail}
							onChange={(e) => setNewLink({...newLink, thumbnail: e.target.value})}
						/>
					</div>
				</div>
				{/* title */}
				<div class="field">
					<label class="label">Title</label>
					<div class="control">
						<input class="input" type="text" placeholder="Title"
							value={newLink.title}
							onChange={(e) => setNewLink({...newLink, title: e.target.value})}
						/>
					</div>
				</div>
				{/* knowledge content */}
				<div class="field">
					<label class="label">Knowledge Content</label>
					<div class="control">
						<textarea class="textarea" placeholder="Knowledge Content"
							value={newLink.knowledge_content}
							onChange={(e) => setNewLink({...newLink, knowledge_content: e.target.value})}
						></textarea>
					</div>
				</div>
				{/* any error message */}
				{errorMessage && <div class="notification is-danger">
					{errorMessage}
				</div>}
			</section>
			<footer class="modal-card-foot">
				<button class="button is-success"
					onClick={async () => {
						try {
							await updataLink(newLink)
							closeLinkEditorModal()
						} catch (error) {
							const error_detail = JSON.parse(error.message);
							if (error_detail.error === "DuplicateURLError") {
								setErrorMessage("URL exist: " + error_detail.url);
							}
						}
				}}>Save changes</button>
				<button class="button" aria-label="close"
					onClick={closeLinkEditorModal}
				>
					Cancel
				</button>
			</footer>
		</div>
	</div>);
}

const SitemapImportModal = ({selectedBot, setLinkTabHintMessage, closeShowSitemapImportModal}) => {
	const [baseURL, setBaseURL] = useState('');
	const [sitemapURL, setSitemapURL] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const validateBaseURL = () => {
		if (baseURL === '') {
			setErrorMessage('Base URL is required');
			setSuccessMessage('');
			return false;
		}
		if (!baseURL.startsWith('http://') && !baseURL.startsWith('https://')) {
			setErrorMessage('Base URL must start with http:// or https://');
			setSuccessMessage('');
			return false;
		}
		if (baseURL.endsWith('/')) {
			setErrorMessage('Base URL must not end with /');
			setSuccessMessage('');
			return false;
		}
		setErrorMessage('');
		setSuccessMessage('Base URL is valid');
		return true;
	}

	const validateSitemapURL = () => {
		if (sitemapURL === '') {
			setErrorMessage('Sitemap URL is required');
			setSuccessMessage('');
			return false;
		}
		if (!sitemapURL.startsWith('http://') && !sitemapURL.startsWith('https://')) {
			setErrorMessage('Sitemap URL must start with http:// or https://');
			setSuccessMessage('');
			return false;
		}
		if (!sitemapURL.endsWith('.xml')) {
			setErrorMessage('Sitemap URL must end with .xml');
			setSuccessMessage('');
			return false;
		}
		return true;
	}

	return (<div class="modal is-active" tabindex="-1" role="dialog">
		<div class="modal-background"></div>
		<div class="modal-card">
			<header class="modal-card-head">
				Import Knowledge Links via sitemap.xml
			</header>
			<div class="modal-card-body">
				{errorMessage && <div class="notification is-danger">
					{errorMessage}
				</div>}
				{successMessage && <div class="notification is-success">
					{successMessage}
				</div>}
				<div class="field is-grouped has-addons">
					<label class="label">Base URL</label>
					<input class="input is-small" type="text"
						placeholder="https://www.example.com" 
						onInput={(e) => setBaseURL(e.target.value)}/>
				</div>
				<div class="field is-grouped has-addons">
					<label class="label">Sitemap URL</label>
					<input class="input is-small" type="text" 
						placeholder="https://www.example.com/sitemap.xml" 
						onInput={(e) => setSitemapURL(e.target.value)}/>
				</div>

				<div>
					<strong>Disclaimer and Tips</strong>
					<ul style="list-style-type: disc; padding-left: 20px;">
						<li>You should have the right to access all the URLs listed in the sitemap, and delete any URL you don't want to or should not be imported as your knowledge links.</li>
						<li>It is your responsibility to ensure that using those knowledge links are legal and do not violate any applicable law, or our Terms of Use.</li>
						<li>Site Chat will respect rules set in the robots.txt file for a general bot.</li>
						<li>If a URL is already in the knowledge base, it will not be imported or updated, so that your previouse refinement will not be overwritten.</li>
						<li>It is recommended to update the knowledge content of the imported links after importing to improve the quality of the knowledge base.</li>
					</ul>
				</div>
			</div>
			<footer class="modal-card-foot">
				<button class="button is-primary is-small"
					onClick={() => {
						// validate base url
						let valid = validateBaseURL();
						if (valid) {
							setErrorMessage('');
							valid = validateSitemapURL();
						}
						if (valid) {
							setErrorMessage('');
							sendAPIRequest(`/api/v1/bots/${selectedBot._id}/knowledge_link_list/import_sitemap`, 'POST', {
								base_url: baseURL,
								sitemap_url: sitemapURL
							});
							setLinkTabHintMessage('Importing sitemap started, come back to the Knowledge Links tab in ablout 10 minutes.');
							closeShowSitemapImportModal();
						}
					}}
				>Import</button>
				<button class="button is-small" aria-label="close"
					onClick={closeShowSitemapImportModal}
				>Close</button>
			</footer>
		</div>
	</div>);
}

const KnowledgeLinks = ({ selectedBot }) => {
	const [linksPerPage, setLinksPerPage] = useState(10);
	const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
	const [totalPaginationPages, setTotalPaginationPages] = useState(1);

	const [linkList, setLinkList] = useState([]);
	const [linkListSortOrder, setLinkListSortOrder] = useState('desc'); // asc or desc

	const [showLinkEditorModal, setShowLinkEditorModal] = useState(false);
	const [editingLink, setEditingLink] = useState(null);

	const [showSitemapImportModal, setShowSitemapImportModal] = useState(false);
	const [linkTabHintMessage, setLinkTabHintMessage] = useState('');

	useEffect(async () => {
		await fetch_knowledge_links();
	}, [selectedBot._id]);

	const fetch_knowledge_links = async () => {
		const link_list = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/knowledge_link_list`, 'GET')
		link_list.sort((a, b) => {
			if (linkListSortOrder === 'dec') {
				return a.created_at - b.created_at;
			} else {
				return b.created_at - a.created_at;
			}
		})
		setLinkList(link_list);
	}

	useEffect(() => {
		setTotalPaginationPages(Math.ceil(linkList.length / linksPerPage));		
	}, [linkList, linksPerPage]);

	const updateLink = async (link) => {
		const new_link = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/knowledge_link_list/${link._id}`, 'PUT', link);
		setLinkList((prevLinkList) => {
			const linkIndex = prevLinkList.findIndex((link) => link._id === new_link._id);
			const newLinkList = [...prevLinkList];
			newLinkList[linkIndex] = new_link;
			return newLinkList;
		});
	}

	const insertLink = async (link) => {
		const new_link = await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/knowledge_link_list`, 'POST', link);
		setLinkList((prevLinkList) => [new_link, ...prevLinkList]);
		setShowLinkEditorModal(true);
		setEditingLink(new_link);
	}

	const closeLinkEditorModal = async () => {
		setShowLinkEditorModal(false);
		setEditingLink(null);
		await fetch_knowledge_links();
	}

	const deleteLink = async (link) => {
		await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/knowledge_link_list/${link._id}`, 'DELETE');
		setLinkList((prevLinkList) => {
			const linkIndex = prevLinkList.findIndex((old_link) => old_link._id === link._id);
			const newLinkList = [...prevLinkList];
			newLinkList.splice(linkIndex, 1);
			return newLinkList;
		});
	}

	const datetime_formatter = (time) => {
		if (time) {
			let date = new Date(time*1000);
			return date.getFullYear() + '-' +
			(date.getMonth()+1).toString().padStart(2, '0') + '-' +
			date.getDate().toString().padStart(2, '0') + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' + 
            date.getMinutes().toString().padStart(2, '0') + ':' +
            date.getSeconds().toString().padStart(2, '0');
		} else {
			return '-';
		}
	}

	return (<div>
		{showLinkEditorModal && <LinkEditorModal 
			link={editingLink} 
			updataLink={updateLink}
			closeLinkEditorModal={closeLinkEditorModal}
		/>}

		{showSitemapImportModal && <SitemapImportModal
			selectedBot={selectedBot}
			setLinkTabHintMessage={setLinkTabHintMessage}
			closeShowSitemapImportModal={() => setShowSitemapImportModal(false)}
		/>}

		{(linkTabHintMessage.length > 0) && (<div className='notification is-success is-light'>
			<button class="delete" onClick={() => setLinkTabHintMessage('')}></button>
			{linkTabHintMessage}
		</div>)}

		<div class="field">
			<label class="label">
				<div class="button ml-2 is-small is-primary"
					onClick={ async () => {
						await insertLink({
							url: '',
							thumbnail: '',
                            title: '',
                            knowledge_content: '',
						});
					}}
				>
					<i class="material-icons mr-1"
					>note_add</i> 
					Add
				</div>

				<div class="button ml-2 is-small is-primary"
					onClick={ () => {
						setShowSitemapImportModal(true);
					}}
				>
					<i class="material-icons mr-1"
					>download</i> 
					Batch Import
				</div>
			</label>
		</div>
		<nav class="pagination is-centered is-small " pagination-color="#FFFFFF" role="navigation" aria-label="pagination">
			<ul class="pagination-list">
			{ currentPaginationPage > 2 &&
			<li>
				<a class="pagination-link" aria-label="Goto page 1"
					onClick={() => setCurrentPaginationPage(1)}
				>1</a>
			</li>
			}
			{ currentPaginationPage > 3 &&
			<li>
				<span class="pagination-ellipsis">&hellip;</span>
			</li>
			}
			{ currentPaginationPage > 1 &&
			<li>
				<a class="pagination-link" aria-label="Goto previous page"
					onClick={() => setCurrentPaginationPage(currentPaginationPage - 1)}
				>{currentPaginationPage - 1}</a>
			</li>
			}
			<li>
				<a class="pagination-link is-current" aria-label={currentPaginationPage} 
					aria-current="page">{currentPaginationPage}</a>
			</li>
			{ currentPaginationPage + 1 <= totalPaginationPages &&
			<li>
				<a class="pagination-link" aria-label="Goto next page"
					onClick={() => setCurrentPaginationPage(currentPaginationPage + 1)}
				>{currentPaginationPage + 1}</a>
			</li>
			}
			{ currentPaginationPage + 3 <= totalPaginationPages &&
			<li>
				<span class="pagination-ellipsis">&hellip;</span>
			</li>
			}
			{ currentPaginationPage + 2 <= totalPaginationPages &&
			<li>
				<a class="pagination-link" aria-label={totalPaginationPages}
					onClick={() => setCurrentPaginationPage(totalPaginationPages)}
				> {totalPaginationPages} </a>
			</li>
			}
			</ul>
		</nav>

        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Card Preview</th>
                    <th>URL</th>
                    <th>Thumbnail</th>
                    <th>Title</th>
                    <th>Knowledge Content</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                { linkList.slice((currentPaginationPage - 1) * linksPerPage, currentPaginationPage * linksPerPage).map((link, i) => (
                    <tr key={(currentPaginationPage - 1) * linksPerPage + i}>
                        <td>
							<LinkPreviewCard link={link} />
                        </td>
                        <td>
							{link.url}
                        </td>
                        <td>
							{link.thumbnail && <div>
								<figure class="image is-48x48">
									<img src={link.thumbnail} alt="link image"/>
								</figure>
							</div>}
                        </td>
                        <td>
                            {link.title}
                        </td>
                        <td>
							<div className="ellipsis-multiline">
								{link.knowledge_content}
							</div>
                        </td>
                        <td>
                            <div class="buttons">
								<button class="button is-small is-primary"
                                    onClick={() => {
										setEditingLink(link);
										setShowLinkEditorModal(true);
									}}
                                >
                                    <i class="material-icons">edit</i>
                                </button>
                                <button class="button is-small is-danger"
                                    onClick={() => deleteLink(link)}
                                >
                                    <i class="material-icons">delete</i>
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
	</div>)
}

export default KnowledgeLinks;