import { h } from 'preact';

import Markdown from 'markdown-to-jsx'

import intergration_website from './intergration/website/content.md';
import intergration_shopify from './intergration/shopify/content.md';
import intergration_wix from './intergration/wix/content.md';
import intergration_squarespace from './intergration/squarespace/content.md';
import intergration_hubspot from './intergration/hubspot/content.md';
import intergration_google_sites from './intergration/google_sites/content.md';

import tutorial_conversion_tracking from './tutorials/conversion_tracking/content.md';

import stroy_1 from './stories/story_1/content.md';

const documentations = [
    { cat_name: 'integrations', cat_title: 'Integrations', docs: [{
        doc_name: 'shopify', doc_title: 'Shopify', doc_content: intergration_shopify
      }, {
        doc_name: 'website', doc_title: 'Website', doc_content: intergration_website
      }, {
        doc_name: 'wix', doc_title: 'Wix', doc_content: intergration_wix
      }, {
        doc_name: 'squarespace', doc_title: 'Squarespace', doc_content: intergration_squarespace
      }, {
        doc_name: 'hubspot', doc_title: 'HubSpot', doc_content: intergration_hubspot
      }, {
        doc_name: 'google_sites', doc_title: 'Google Sites', doc_content: intergration_google_sites
      }
    ]},
    { cat_name: 'tutorials', cat_title: 'Tutorials', docs: [{
        doc_name: 'conversion_tracking', doc_title: 'Conversion Tracking', doc_content: tutorial_conversion_tracking
      }
    ]},
    { cat_name: 'stories', cat_title: 'Stories', docs: [{
        doc_name: 'story_1', doc_title: 'Beyond Bots: Battle for Winning the Customer Service Game in the AI Era - How Genuine Connections, Swift Responses, and Strategic Partnerships Shape Success', doc_content: stroy_1
      }
    ]}
];

const IntegrationList = [    
    {
      "platform_type": "E-commerce Platforms",
      "platforms": [
        {
          "name": "Shopify",
          "doc_folder": "shopify",
          "description": "Dedicated e-commerce platform for online stores and retail point-of-sale systems."
        },
        // {
        //   "name": "Magento",
        //   "doc_folder": "magento",
        //   "description": "Open-source e-commerce platform."
        // },
        // {
        //   "name": "WooCommerce",
        //   "doc_folder": "woo_commerce",
        //   "description": "Open-source e-commerce plugin for WordPress."
        // },
        // {
        //   "name": "GoDaddy",
        //   "doc_folder": "godaddy",
        //   "description": "Known for domain registration and hosting, also offers e-commerce solutions."
        // }
      ]
    },
    {
      "platform_type": "Website Builders",
      "platforms": [
        {
          "name": "Wix",
          "doc_folder": "wix",
          "description": "Cloud-based platform for creating HTML5 websites and mobile sites using drag and drop tools."
        },
        {
          "name": "Squarespace",
          "doc_folder": "squarespace",
          "description": "Website builder providing software as a service for website building and hosting."
        },
        // {
        //   "name": "Duda",
        //   "doc_folder": "duda",
        //   "description": "Website builder focused on scalability and speed."
        // },
        // {
        //   "name": "One.com",
        //   "doc_folder": "one_com",
        //   "description": "Website builder and hosting service known for simplicity."
        // },
        // {
        //   "name": "Typo3",
        //   "doc_folder": "typo3",
        //   "description": "Free and open-source web content management system for enterprise-level websites."
        // },
        // {
        //     "name": "Drupal",
        //     "doc_folder": "drupal",
        //     "description": "Versatile CMS, often used for blogging and community-based sites."
        // },
        // {
        //     "name": "Blogger",
        //     "doc_folder": "blogger",
        //     "description": "Specifically designed for blogging, to create and publish blogs."
        // }
      ]
    },
    {
      "platform_type": "Content Management Systems (CMS)",
      "platforms": [
        {
          "name": "HubSpot",
          "doc_folder": "hubspot",
          "description": "Known for marketing, sales, and service software, also offers CMS."
        },
        {
          "name": "Google Sites",
          "doc_folder": "google_sites",
          "description": "Structured wiki- and web page-creation tool offered by Google."
        }
      ]
    }
]

const TutorialList = [
    {
        "name": "Conversion Tracking",
        "doc_folder": "conversion_tracking",
        "description": "Track conversions on your website."
    }
]

const StoryList = [
    {
        "name": "Beyond Bots: Battle for Winning the Customer Service Game in the AI Era - How Genuine Connections, Swift Responses, and Strategic Partnerships Shape Success",
        "doc_folder": "story_1",
        "description": "How Genuine Connections, Swift Responses, and Strategic Partnerships Shape Success"
    }
]

const table_of_content_mapping = {
    'integrations': {
        title: 'Set up Site Chat',
        content_list: IntegrationList
    },
    'tutorials': {
        title: 'Tutorials',
        content_list: TutorialList
    },
    'stories': {
        title: 'Stories',
        content_list: StoryList
    }
}

const DocumentationPage = ({ doc_category, doc_name }) => {
    let doc_cat_list = documentations.find(doc => doc.cat_name === doc_category)
    if (!doc_cat_list) {
      doc_cat_list = documentations.find(doc => doc.cat_name === 'integrations')
    }
    let doc = doc_cat_list.docs.find(doc => doc.doc_name === doc_name);
    if (!doc) {
        // default to website integration
        doc = documentations.find(doc => doc.cat_name === 'integrations').docs.find(doc => doc.doc_name === 'website');
    }

    return (
    <div class="container content" style={{ marginTop: '3rem'}}>
        <div class="columns">
            <div class="column is-three-quarters">
                <Markdown>{doc.doc_content}</Markdown>
            </div>
            {doc_category == 'integrations' && table_of_content_mapping[doc_category] && <div class="column">
                <h2>{table_of_content_mapping[doc_category]['title']}</h2>
                <div key={0} className='mt-5'>
                    <h5>Website Developer</h5>
                    <li key={0}><a href={`/docs/integrations/website`}>Website (Low Code)</a></li>
                </div>
                {
                    table_of_content_mapping[doc_category]['content_list'].map((item, index) => (
                        <div key={index} className='mt-5'>
                            <h5>{item.platform_type}</h5>
                            <ul>
                                {
                                    item.platforms.map((platform, index) => (
                                        <li key={index}><a href={`/docs/integrations/${platform.doc_folder}`}>{platform.name}</a></li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>}

            {doc_category == 'tutorials' && table_of_content_mapping[doc_category] && <div class="column">
                <h2>{table_of_content_mapping[doc_category]['title']}</h2>
                <ul>
                    {
                        table_of_content_mapping[doc_category]['content_list'].map((item, index) => (
                            <li key={index}><a href={`/docs/tutorials/${item.doc_folder}`}>{item.name}</a></li>
                        ))
                    }
                </ul>
            </div>}

            {doc_category == 'stories' && table_of_content_mapping[doc_category] && <div class="column">
                <h2>{table_of_content_mapping[doc_category]['title']}</h2>
                <ul>
                    {
                        table_of_content_mapping[doc_category]['content_list'].map((item, index) => (
                            <li key={index}><a href={`/docs/stories/${item.doc_folder}`}>{item.name}</a></li>
                        ))
                    }
                </ul>
            </div>}
        </div>
    </div>
)};

export default DocumentationPage;
