import { h } from 'preact';
import CodeBlock from '../../components/code_block';
// import Markdown from 'markdown-to-jsx'

import { useState, useEffect, useContext } from 'preact/hooks';
import GlobalStateContext from '../../context/GlobalStateContext';
import './style.css';

import img_feature1 from './images/feature_1.png';
import img_feature2 from './images/feature_2.png';
import img_feature3 from './images/feature_3.png';
import img_feature4 from './images/feature_4.png';

import { stripe, chat_site_official_bot_id } from '../../utils';

// Hero Section
const HeroSection = () => {
    const { isUserLoggedIn, triggerLoginModalOn } = useContext(GlobalStateContext);

    const [activeFeature, setActiveFeature] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovering) {
                setActiveFeature((prevFeature) => (prevFeature + 1) % features.length);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [isHovering]);

    const features = [
        {
            title: "Quick Setup",
            text: "Set up a chatbot in 3 minutes with no coding required.",
            image: img_feature1,
        }, {
            title: "Engagement Analytics",
            text: "Harness AI for deeper insights into customer's needs.",
            image: img_feature3,
        }, {
            title: "Customizable Themes",
            text: "Sync your chatbot's themes to match your brand perfectly.",
            image: img_feature2,
        }, {
            title: "Adaptive Visibility Management",
            text: "Use your bot profile page for a firsthand look; 1-click bot visibility controle on your site.",
            image: img_feature4,
        }, {
            title: "Easy Integration",
            text: "Add a chatbot with a no-code plugin or at most 1 line of code!",
            code: 
`            
<html>
<head>
</head>
<body>
    <!-- Add the following script to the end of the body tag -->
    <script data-bot-id="C-your-bot-id" id="site-chat-script" 
        src="https://www.site-chat.com/lib/site-chat.js" type="module">
    </script>
</body>
</html>
`,
        },
    ];

    return (
    <section className="hero is-primary is-bold is-fullheight-with-navbar">
        <div className="hero-body">
            <div className="container has-text-centered">
                {/* 
                <h1 className="title is-1  mt-6">Revolutionize Your Customer Engagement</h1>
                <h2 className="subtitle is-3">Intuitive Chat Solutions for Your Website</h2>
                 */}
                <div className="title is-1  mt-6">Let ChatGPT work for your online store</div>
                <div className="subtitle is-4">Let ChatGPT chat to your customers & Let GenAI generate profits for your business</div>
                {/* {!isUserLoggedIn && <button className="button is-medium is-success is-light mr-3"
                    onClick={() =>{
                        const sample_questions = [
                            'Is Site Chat the right fit for me?',
                            'What are the benefits of using AI in customer service?',
                            'How does AI help in the personalization of customer experiences?',
                            'What kind of industries can benefit from incorporating AI customer service?',
                            'What are some common applications of AI in e-commerce?',
                        ]
                        const message_content = sample_questions[Math.floor(Math.random() * sample_questions.length)];
                        window.siteChatDataLayer.push({
                            event: 'newClientMessage',
                            botId: chat_site_official_bot_id,
                            message_type: 'text',
                            content: message_content
                        });
                    }}
                >Ask me Anything</button>} */}
                <button className="button is-medium is-success is-light mr-3"
                    onClick={() =>{
                        window.siteChatDataLayer.push({
                            event: 'newClientMessage',
                            botId: chat_site_official_bot_id,
                            message_type: 'text',
                            content: 'How could Site Chat help my online store?'
                        });
                    }}
                >How could Site Chat help my online store?</button>
                
                <button className="button is-medium is-success is-light"
                    onClick={() =>{
                        if (isUserLoggedIn) {
                            window.location.href='/console';
                        } else {
                            triggerLoginModalOn();
                        }
                    }}
                >{isUserLoggedIn ? 'Go to Console' : 'Try for Free'}</button>

                <div className="feature-area mt-6"
                    onMouseEnter={() => setIsHovering(true)} 
                    onMouseLeave={() => setIsHovering(false)}
                >
                    {features.map((feature, index) => (
                    <div className={`feature-content columns ${index === activeFeature ? 'active' : ''}`} key={index}>
                        <div className="column is-4">
                            <div>
                                <h4 className="title is-3">{feature.title}</h4>
                                <p className="title is-5">{feature.text}</p>
                            </div>
                        </div>
                        <div className="column is-8">
                            {feature.image && <img 
                                src={feature.image}
                                height={480}
                                alt={`Feature ${index + 1}`} 
                                style={{
                                    maxHeight: '480px',
                                    maxWidth: '100%',
                                    height: 'auto', // Auto height to maintain aspect ratio
                                    width: 'auto',  // Auto width to maintain aspect ratio
                                }}
                            />}
                            {feature.code && <CodeBlock language="javascript" code={feature.code} /> }
                            {/* {feature.code && <Markdown>{feature.code}</Markdown> } */}
                        </div>
                    </div>
                    ))}
                </div>

                <div className="feature-indicators">
                    {features.map((_, index) => (
                        <div className={`feature-indicator ${index === activeFeature ? 'active' : ''}`} key={index}></div>
                    ))}
                </div>                
            </div>
        </div>
    </section>
)};

const TechSection = () => (
    <section className="section" id="tech">
        <div className="container">
            <h3 className="title is-3 has-text-centered">Powered by</h3>
            <div className="columns is-mobile is-multiline">
                <div className="column has-text-centered is-one-quarter-tablet is-half-mobile">
                    <img height={60} style={{height: '60px'}} src="https://upload.wikimedia.org/wikipedia/commons/4/4d/OpenAI_Logo.svg" alt="Open AI Logo" />
                </div>
                <div className="column has-text-centered is-one-quarter-tablet is-half-mobile">
                    <img height={60} style={{height: '60px'}} src="https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" alt="AWS Logo" />
                </div>
                <div className="column has-text-centered is-one-quarter-tablet is-half-mobile">
                    <img height={60} style={{height: '60px'}} src="https://upload.wikimedia.org/wikipedia/commons/a/a4/NVIDIA_logo.svg" alt="NVIDIA Logo" />
                </div>
                <div className="column has-text-centered is-one-quarter-tablet is-half-mobile">
                    <img height={60} style={{height: '60px'}} src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg" alt="Google Logo" />
                </div>
            </div>
        </div>
    </section>
);

const UseCasesSection = () => (
    <section className="section" id="use-cases">
        <div className='container'>
            <h3 className="title is-3 has-text-centered">Better User Experience</h3>

            <div className="columns is-mobile is-multiline">
                <div className="column has-text-centered is-one-third-tablet is-full-mobile">
                    <div className="card">
                        <header className="card-header has-background-primary">
                            <p class='card-header-title has-text-centered is-primary' style="justify-content: left; color:white;">
                                Online Store
                            </p>
                        </header>
                        <div className="card-content has-text-left">
                            Enhances shopping experience by providing instant, personalized product recommendations and answering customer queries efficiently.                            
                        </div>
                        <footer className="card-footer">
                            <a href="https://shopify.site-chat.com" target='_blank' className="card-footer-item">Visit Demo Store</a>
                        </footer>
                    </div>
                </div>
                <div className="column has-text-centered is-one-third-tablet is-full-mobile">
                    <div className="card">
                        <header className="card-header has-background-primary">
                            <p class='card-header-title has-text-centered is-primary' style="justify-content: left; color:white;">
                                Tech Doc
                            </p>
                        </header>
                        <div className="card-content has-text-left">
                            Streamlines access to relevant technical information, making it easier for users to find specific documentation and troubleshoot issues quickly.
                        </div>
                        <footer className="card-footer">
                            <a className="card-footer-item"
                                onClick={() =>{
                                    const message_content = 'Show me a step-by-step tutorial on how to add a chatbot to my website using Site Chat.';
                                    window.siteChatDataLayer.push({
                                        event: 'newClientMessage',
                                        botId: chat_site_official_bot_id,
                                        message_type: 'text',
                                        content: message_content
                                    });
                                }}
                            >
                                Ask Me
                            </a>
                        </footer>
                    </div>
                </div>
                <div className="column has-text-centered is-one-third-tablet is-full-mobile">
                    <div className="card">
                        <header className="card-header has-background-primary">
                            <p class='card-header-title has-text-centered is-primary' style="justify-content: left; color:white;">
                                Company Website
                            </p>
                        </header>
                        <div className="card-content has-text-left">
                            Improves visitor engagement by offering instant assistance, answering FAQs, and guiding users to the appropriate resources or contact points on the website.
                        </div>
                        <footer className="card-footer">
                            <a className="card-footer-item"
                                onClick={() =>{
                                    const message_content = 'What is does Site Chat do?';
                                    window.siteChatDataLayer.push({
                                        event: 'newClientMessage',
                                        botId: chat_site_official_bot_id,
                                        message_type: 'text',
                                        content: message_content
                                    });
                                }}
                            >
                                Ask Me
                            </a>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const PricingFeature = ({ text, tooltip }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    // useEffect(() => {
    //     setIsTooltipVisible(true);
    //     setIsTooltipVisible(false);        
    // }, []);

    const toggleTooltip = () => {
        setIsTooltipVisible(!isTooltipVisible);
    };

    return (
        <div
            style={{
                marginBottom: '0.3rem',
                paddingBottom: '0',
            }}
        >
            <div style={{
                    flexGrow: 1,
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word'
            }}
            onClick={toggleTooltip}
        >
                {text}
                <span style={{
                    fontSize: '1rem',
                    verticalAlign: 'text-top',
                }}>
                    <div className="tooltip-container">
                        <span className="icon">
                            <i className="material-icons" style={{fontSize: '18px'}}>{tooltip ? 'help' : ''}</i>
                        </span>
                        {isTooltipVisible && (<span className="tooltip-text">{tooltip}</span>)}
                    </div>
                </span>     
            </div>
                                                    
        </div>
    );
}

const PricingSection = () => {
    const { isUserLoggedIn, triggerLoginModalOn } = useContext(GlobalStateContext);

    const [planLength, setPlanLength] = useState('monthly');

    const plans = [{
        name: 'Developer',
        benefit: 'Set up in 3 minutes and co-create values with AI.',
        objective: 'Get a taste of what AI can do.',
        price: {
            monthly: '$0',
            yearly: '$0',
        },
        features: [
            {'text': '✅ 2 chatbots', 'tooltip': '' }, 
            {'text': '✅ 200 messages/mo', 'tooltip': '' }, 
            {'text': '✅ $0.02/message PAYG', 'tooltip': '' }, 
            {'text': '✅ $1 Monthly Credit Grants', 'tooltip': 'After payment method verified, can be used for all PAYG services, expires every billing cycle.' }, 
            {'text': '✅ Monthly Customer Insight Report for all bots', 'tooltip': 'Need at least 100 client messages during the report period.' },
            {'text': '✅ 10 QA fine tune/bot', 'tooltip': '' }, 
        ],
    }, {
        name: 'Starter',
        benefit: 'Deliver value to the customers before documenting everything on the page.',
        objective: 'Uncover what your first 100 customers want most.',
        price: {
            monthly: '$19',
            yearly: '$190',
        },
        features: [
            {'text': '✅ 2 chatbots', 'tooltip': '' }, 
            {'text': '✅ 2k messages/mo', 'tooltip': '' }, 
            {'text': '✅ $0.02/message PAYG', 'tooltip': '' }, 
            {'text': '✅ $2 Monthly Credit Grants', 'tooltip': 'Can be used for all PAYG services, expires every billing cycle.' }, 
            {'text': '✅ Monthly Customer Insight Report for all bots', 'tooltip': 'Need at least 100 client messages during the report period.' },
            {'text': '✅ 50 QA fine tune/bot', 'tooltip': '' }, 
            {'text': '✅ Customised Themes', 'tooltip': '' }, 
        ],
    }, {
        name: 'Growth',
        benefit: 'Identify customer needs ahead of competitors.',
        objective: 'Grow towards $10K MRR.',
        price: {
            monthly: '$99',
            yearly: '$990',
        },
        features: [
            {'text': '✅ 10 chatbots', 'tooltip': '' }, 
            {'text': '✅ 10k messages/mo', 'tooltip': '' }, 
            {'text': '✅ $0.02/message PAYG', 'tooltip': '' }, 
            {'text': '✅ $10 Monthly Credit Grants', 'tooltip': 'Can be used for all PAYG services, expires every billing cycle.' }, 
            {'text': '✅ Weekly Customer Insight Report for all bots', 'tooltip': 'Need at least 100 client messages during the report period.' },
            {'text': '✅ 100 QA fine tune/bot', 'tooltip': '' }, 
            {'text': '✅ Customised Themes', 'tooltip': '' },
        ],
    }, {
        name: 'Scale',
        benefit: 'Continuously deliver value to a growing customer base.',
        objective: 'Grow towards $10 million ARR',
        price: {
            monthly: '$399',
            yearly: '$3990',
        },
        features: [
            {'text': '✅ 50 chatbots', 'tooltip': '' }, 
            {'text': '✅ 40k messages/mo', 'tooltip': '' }, 
            {'text': '✅ $0.02/message PAYG', 'tooltip': '' }, 
            {'text': '✅ $40 Monthly Credit Grants', 'tooltip': 'Can be used for all PAYG services, expires every billing cycle.' }, 
            {'text': '✅ Weekly Customer Insight Report for all bots', 'tooltip': 'Need at least 100 client messages during the report period.' },
            {'text': '✅ 100 QA fine tune/bot', 'tooltip': '' }, 
            {'text': '✅ Customised Themes', 'tooltip': '' }, 
            {'text': '✅ Customised Branding Text', 'tooltip': '' }, 
        ],
    }];


    return (<section className="section" id="pricing-table">
        <div className="container">
            <h3 className="title is-3 has-text-centered">Pay as you Grow</h3>
            <div class="buttons has-addons is-centered">
                <button className={`button is-primary ${planLength === 'monthly' ? '' : 'is-outlined'}`} onClick={() => setPlanLength('monthly')}>Monthly</button>                
                <button className={`button is-primary ${planLength === 'yearly' ? '' : 'is-outlined'}`} onClick={() => setPlanLength('yearly')}>Yearly (save 2 mo)</button>
            </div> 
                    
            
            <div className="columns">
                {plans.map(plan => (
                    <div className="column is-3-desktop is-6-tablet is-12-mobile">
                        <div className="card">
                            {/* <header className="card-header">
                                <p className="card-header-title"></p>
                            </header> */}
                            <div className="card-content">
                                <p className='title'>{plan.name}</p>

                                <p className='title is-6' style={{height: '3.5rem'}}>{plan.benefit}</p>

                                <p className='title is-6' style={{height: '3rem'}}>{plan.objective}</p>


                                {planLength === 'monthly' && <p className="subtitle">{plan['price']['monthly']}  / mo</p>}
                                {planLength === 'yearly' && <p className="subtitle">{plan['price']['yearly']}  / yr</p>}

                                { plan.name === 'Developer' ? 
                                    <button className="button is-fullwidth is-primary"
                                        onClick={() =>{
                                            if (isUserLoggedIn) {
                                                window.location.href='/console';
                                            } else {
                                                triggerLoginModalOn();
                                            }
                                        }}
                                    >{isUserLoggedIn ? 'Go to Console' : 'Start for Free'}</button> : 
                                    <button className="button is-fullwidth is-primary is-outlined"
                                        onClick={() =>{
                                            if (isUserLoggedIn) {
                                                window.location.href='/console';
                                            } else {
                                                triggerLoginModalOn();
                                            }
                                        }}
                                    >{isUserLoggedIn ? 'Go to Console' : 'Get Started'}</button>}
                                <div className='mt-5'>
                                    {plan.features.map(feature => (
                                        <PricingFeature text={feature.text} tooltip={feature.tooltip} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>);
}

const FeatureComparisonSection = () => (
    <section className="section" id="feature-comparison">
        <h3 className="title is-3 has-text-centered">AI-Enhanced Improvements </h3>
        <table class="container table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
            <tr>
            <th>Site Chat</th>
            <th>Traditional Chatbot</th>
            </tr>
        </thead>
        <tbody>
        <tr>
                <td><strong>24/7 Interaction:</strong> Constant engagement, reduces staff workload.</td>
                <td>Limited intelligence, ironically, often defers to human support and increase workload.</td>
            </tr>
            <tr>
                <td><strong>Efficient Single-Language Configuration:</strong> Set up in English or your native language within 3 minutes, enabling comprehensive multilingual support without additional effort or cost.</td>
                <td>Complex, time-consuming Q&A logic setup; language-specific configurations.</td>
            </tr>
            <tr>
                <td><strong>Data-Driven Insights:</strong> AI analyzes customer interactions for continuous improvement.</td>
                <td>Struggles with unplanned queries and context recognition.</td>
            </tr>
            <tr>
                <td><strong>Exclusive Knowledge Training:</strong> Your chatbot is uniquely trained on your provided knowledge, enhanced with general public information, ensuring protection against competitor contamination and data theft.</td>
                <td>Simple Q&A logic, vulnerable to replication.</td>
            </tr>
            <tr>
                <td><strong>Seamless Integration:</strong> Quick installation, wide platform compatibility.</td>
                <td>Requires specialized training, limited platform support.</td>
            </tr>
            </tbody>
        </table>
    </section>
);

     


const CommonQuestionSection = () => {
    const question_list = [
        'Can I try Site Chat for free?',
        "I don't know coding, how can I add a chatbot to my website?",
        "How can I customize the chatbot's theme to match my brand and website design?",
        'How can I train my own chatbot?',
        "How could AI help me training a better chatbot?",
        "How can I ensure that my training data remains secure and isn't utilized by others?",
        "The bot gives misinformation about my product and service.  How can I fix it?",
    ]
    
    return (
    <section className="section" id="testimonials">
        <div className="container">
            <h3 className="title is-3 has-text-centered">Common Questions</h3>
            { question_list.map((question, index) => (
                <div key={index} className='field  has-addons'
                    style={{cursor: 'pointer'}}
                    onClick={() =>{
                        const message_content = question;
                        window.siteChatDataLayer.push({
                            event: 'newClientMessage',
                            botId: chat_site_official_bot_id,
                            message_type: 'text',
                            content: message_content
                        });
                    }}
                >
                    <i className="material-icons mr-3">help</i>
                    <div className="">  {question}</div>
                </div>
            ))
            }
        </div>
    </section>
)}

// Testimonials Section
const TestimonialsSection = () => (
    <section className="section" id="testimonials">
        <div className="container">
            <h3 className="title is-3 has-text-centered">What Our Users Say</h3>
            {/* Testimonials carousel or tiles */}
        </div>
    </section>
);

const Home = () => (
    <div>
        <HeroSection />    
        <UseCasesSection />
        <FeatureComparisonSection />
        <CommonQuestionSection />        

        <TechSection />            
        <PricingSection />
        {/* <TestimonialsSection /> */}
    </div>
);

export default Home;
