import { h } from 'preact';
import { useEffect, useState, useContext } from 'preact/hooks';
import config from '../../../config';
const server_api = config.serverAPIHost;

// https://www.site-chat.com/reset-password?p={reset_password_token}
const ResetPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const [resetToken, setResetToken] = useState('');
    const [resetTokenInvalid, setResetTokenInvalid] = useState(false);

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    };
    // get reset_password_token
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const reset_password_token = urlParams.get('p');
        if (reset_password_token) {
            setResetToken(reset_password_token);
        }
    }, []);

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        setIsEmailValid(validateEmail(email));
    }

    const handleResetPassword = async (event) => {
        event.preventDefault();

        const response = await fetch(`${server_api}/api/v1/users/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
                password_reset_token: resetToken,
            })
        });
        if (response.status === 200) {
            setMessage('Password reset successful.');
            return
        } else if (response.status === 401) {
            // message details
            const data = await response.json();
            setMessage(`Password reset failed: ${data.detail}`);
        }
        setResetTokenInvalid(true);
    }

    const handleResetPasswordRequest = async (event) => {
        event.preventDefault();
        const response = await fetch(`${server_api}/api/v1/users/reset-password-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,  // Assuming you're using email as username
            })
        });
        try {
            const data = await response.json();
        } catch (error) {
        } finally {
            setMessage(`An email has been sent to ${email} with password reset instructions, if it's in our system. Please check your inbox.`);            
            return;
        }
    }

    return (
        <div className="container" style={{marginTop: '3rem'}}>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1 className="title is-3">Forgot your password? </h1>
                    <p>Please enter your email and new password below, and we’ll help you reset your password.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 offset-md-3">

                <div className='mt-3'>
                    <div class="field">
                        <label class="label">Email</label>
                        <div class="control">
                            <input 
                                class={`input ${isEmailValid ? '' : 'is-danger'}`} 
                                type="text" 
                                placeholder="Email" 
                                value={email} 
                                onInput={handleEmailChange} 
                            />
                            {!isEmailValid && <p class="help is-danger">Invalid email format</p>}
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Password</label>
                        <div class="control">
                            <input class="input" type="password" placeholder="Password" value={password} onInput={e => setPassword(e.target.value )} />
                            password should be at least 8 chars long. 
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <button class="button is-primary is-small" 
                                disabled={!email || !isEmailValid || !password || password.length < 8 }
                                onClick={handleResetPassword}
                            >Reset Password</button>
                            <button type="submit" className="button is-small ml-3" onClick={handleResetPasswordRequest}>Resend Password Reset Email</button>
                        </div>                        
                    </div>
                </div>

                {message && <div className="alert alert-success" role="alert">
                    {message}
                </div>}

                
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordPage;