// import ReactQuill from "react-quill";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// import "quill/dist/quill.snow.css"; // 导入Quill样式
import { useRef, useState } from "react";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import java from "highlight.js/lib/languages/java";
import css from "highlight.js/lib/languages/css";

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("java", java);
hljs.registerLanguage("css", css);

import "./index.css";

// hljs.configure({
//   // optionally configure hljs
//   languages: ["javascript", "css", "java"]
// });

export default function App() {
  const [showPreview, setShowPreview] = useState(false);
  const [text, updateText] = useState("");
  // const [textInput, updateTextInput] = useState("");
  const quillRef = useRef(null);
  // quillRef.get
  let data = text;
  const handleChange = (e) => {
    // data = e;
    updateText(e);
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], //开关按钮
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }], //自定义按钮值
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // 上标/下标
    [{ indent: "-1" }, { indent: "+1" }], // 减少缩进/缩进
    [{ direction: "rtl" }], // 文本方向
    [{ size: ["small", false, "large", "huge"] }], // 自定义下拉
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], //使用主题的默认下拉
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], //移除格式化
    ["image"],
    ["video"],
    ["formula"],
    [
      {
        imageResize: {
          modules: ["Resize", "DisplaySize", "Toolbar"],
        },
      },
    ],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  const [quillData, updateQuillData] = useState([]);

  var cfg = {};

  const handleSubmit = () => {
    console.log("Submit");
  }

  const previewHtml = (d) => {
    setShowPreview(true);
    setTimeout(() => {
      var converter = new QuillDeltaToHtmlConverter(quillData, cfg);
      // var html = converter.convert();
      const preview = document.querySelector("#preview");
      preview.innerHTML = data;
      preview.querySelectorAll("pre").forEach((p) => {
        hljs.highlightBlock(p);
      });
    }, 0);
    // var converter = new QuillDeltaToHtmlConverter(quillData, cfg);
    // // var html = converter.convert();
    // const preview = document.querySelector("#preview");
    // preview.innerHTML = data;
    // preview.querySelectorAll("pre").forEach((p) => {
    //   hljs.highlightBlock(p);
    // });
  };
  // const insertText = (t) => {
  //   let current = quillRef.current;
  //   let s = current.getEditorSelection();
  //   const cursorPosition = s ? s.index : 0;

  //   if (textInput.length > 0) {
  //     if (t === "html") {
  //       current.editor.clipboard.dangerouslyPasteHTML(
  //         cursorPosition,
  //         textInput,
  //         "silent"
  //       );
  //     } else {
  //       current.editor.insertText(cursorPosition, textInput);
  //     }
  //     current.editor.setSelection(cursorPosition + 1);
  //   }
  // };

  return (
    <div className="wrapper">
      <div className="preview-wrapper">
        <button
          type="button"
          class="preview-btn btn-success"
          onClick={() => {
            previewHtml();
          }}
        >
          preview
        </button>
        <button
          type="button"
          class="preview-btn btn-success ml-4"
          onClick={() => setShowPreview(false)}
        >
          hide preview
        </button>
      </div>

      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        value={text}
        onChange={handleChange}
      />
      <button
        type="button"
        class="submit-btn"
        onClick={handleSubmit}
      >
        Submit
      </button>
      {showPreview && <div id="preview"></div>}
    </div>
  );
}
