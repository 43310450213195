import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { sendAPIRequest } from '../../utils';
import {
    Chart,
    BarController,
    BarElement,
    LineController,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js';
Chart.register(
    LineController,
    LineElement,
    PointElement,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const EngagementAnalytics = ({bot_id}) => {
    // Dummy data as defined above
    const [satisfactionData, setSatisfactionData] = useState([]);

    const [faqReportData, setFaqReportData] = useState([]);
    const [faqReportFilter, setFaqReportFilter] = useState('weekly'); // weekly, monthly, manual
    const [fillteredFaqReports, setFillteredFaqReports] = useState([]);
    const [selectedFaqReportIndex, setSelectedFaqReportIndex] = useState(-1);
    const [showFaqReport, setShowFaqReport] = useState(false);
    const [faqErrorMessage, setFaqErrorMessage] = useState('');

    const satisfactionChartRef = useRef();
    const satisfactionChartInstanceRef = useRef(null);

    const faqChartRef = useRef();
    const faqChartInstanceRef = useRef(null);

    const [selectedFaqCluster, setSelectedFaqCluster] = useState(null);

    useEffect(async () => {
        const satisfactions = await sendAPIRequest(`/api/v1/bots/${bot_id}/customer_satisfaction_daily_reports`)
        setSatisfactionData(satisfactions);

        const faqs = await sendAPIRequest(`/api/v1/bots/${bot_id}/faq_analysis_reports`)
        setFaqReportData(faqs);
        if (faqs.length > 0) {
            setSelectedFaqReportIndex(0);
        } else {
            setSelectedFaqReportIndex(-1);
        }
        setSelectedFaqCluster(null);
    }, [bot_id]);

    const convertTimestampToDateString = (timestamp) => {
        const date = new Date(timestamp * 1000); // Create a date object from the timestamp
        const year = date.getFullYear(); // Get the year
        let month = date.getMonth() + 1; // Get the month (0-indexed, hence add 1)
        let day = date.getDate(); // Get the day of the month

        // Ensure month and day are in two-digit format
        month = (month < 10) ? '0' + month : month;
        day = (day < 10) ? '0' + day : day;

        return year + '-' + month + '-' + day; // Return the formatted date string
    }

    const createSatisfactionChart = (ctx, data) => {
        if (satisfactionChartInstanceRef.current) {
            satisfactionChartInstanceRef.current.destroy();
        }

        satisfactionChartInstanceRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: satisfactionData.map(data => convertTimestampToDateString(data.start_date)),
                datasets: [{
                    label: 'Satisfaction Score',
                    data: satisfactionData.map(data => data.score),
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }, {
                    type: 'bar',  // Specify the type for this specific dataset
                    label: 'Customer Messages Count',
                    data: satisfactionData.map(data => data.client_message_count),
                    backgroundColor: 'rgba(144, 238, 144, 0.6)',  // Light green color
                    borderColor: 'rgba(144, 238, 144, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    x: {
                        autoSkip: true,
                        maxTicksLimit: 5 // Adjust this number based on how many labels you want to show at most
                    },
                    y: {
                        beginAtZero: true,
                        type: 'linear',
                        position: 'left',
                        // Only necessary if you want to use a different scale for the bar chart
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        suggestedMin: 0, // Adjust as per your data
                        suggestedMax: 100, // Adjust as per your data
                    }
                }
            }
        });        
    };
    
    useEffect(() => {
        if (satisfactionChartRef.current) {
            createSatisfactionChart(satisfactionChartRef.current.getContext('2d'), satisfactionData);
        }
    }, [satisfactionData]);

    const createFaqChart = (ctx, report) => {
        if (faqChartInstanceRef.current) {
            faqChartInstanceRef.current.destroy();
        }

        if (report.status === 'pending' || report.status === 'in_progress') {
            setFaqErrorMessage("Report is not ready yet. Please come back later.");
            setShowFaqReport(false);
            return;
        }

        if (report.status === 'failed') {
            setFaqErrorMessage("Failed: "+report.failed_reason);
            setShowFaqReport(false);
            return;
        }
        if (report.status === 'cancelled') {
            setFaqErrorMessage("Cancelled: "+report.cancel_reason);
            // setFaqErrorMessage("Insufficient data to generate report.");
            setShowFaqReport(false);
            return;
        }

        setShowFaqReport(true);
        faqChartInstanceRef.current = new Chart(ctx, {
            type: 'bar', // Use 'bar' type for both vertical and horizontal bars
            data: {
                labels: report.clusters.map(cluster => cluster.questions_summary),
                datasets: [{
                    label: 'Related Queries Count',
                    data: report.clusters.map(cluster => cluster.cluster_questions_count),
                }]
            },
            options: {
                indexAxis: 'y', // 'x' for vertical bars and 'y' for horizontal bars
                scales: {
                    x: {
                        stacked: true,
                        beginAtZero: true,
                    },
                    y: {
                        stacked: true,
                    }
                },
                // plugins: {
                //     legend: {
                //         display: true
                //     }
                // }
                onClick: (event, elements) => {
                    if (elements.length > 0) {
                        const faqIndex = elements[0].index;
                        setSelectedFaqCluster(report.clusters[faqIndex]);
                    }
                }
            },
            // plugins: [{
            //     id: 'labelsInsideBars',
            //     afterDraw: chart => {
            //         const ctx = chart.ctx;
            //         ctx.save();
            //         ctx.textAlign = 'left';
            //         ctx.textBaseline = 'middle';
            //         ctx.fillStyle = "#ffffff";

            //         chart.data.datasets.forEach((dataset, datasetIndex) => {
            //             const meta = chart.getDatasetMeta(datasetIndex);
            //             meta.data.forEach((bar, index) => {
            //             // Draw the label inside the bar
            //             const padding = 5; // Padding from the start of the bar
            //             const label = chart.data.labels[index];
            //             ctx.fillText(label, bar.base + padding, bar.y + bar.height / 2);
            //             });
            //         });
            //         ctx.restore();
            //     }
            // }]
        });
    };

    useEffect(() => {
        const fillteredFaqReports = faqReportData.filter(report => report.report_type === faqReportFilter);
        setFillteredFaqReports(fillteredFaqReports);
        if (fillteredFaqReports.length > 0) {
            setSelectedFaqReportIndex(0);
        }
    }, [faqReportData, faqReportFilter]);

    useEffect(() => {
        if (fillteredFaqReports.length > selectedFaqReportIndex && selectedFaqReportIndex >= 0) {
            const selectedReport = fillteredFaqReports[selectedFaqReportIndex];
            selectedReport && createFaqChart(faqChartRef.current.getContext('2d'), selectedReport);
        }
    }, [selectedFaqReportIndex, fillteredFaqReports]);



    return (
        <div className="container">
            <div className="columns">
                <div className="column is-6">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">30 days Customer Satisfaction Trends</p>
                        </header>
                        <div className="card-content">
                            <canvas ref={satisfactionChartRef}></canvas>
                        </div>
                    </div>
                </div>
            </div>

            
            <label className='label controller mr-3'>FAQ Analysis Report</label>
            <div className="field is-grouped">
                
                <div class="buttons has-addons">
                    <button className={`button is-rounded is-small is-primary ${faqReportFilter === 'weekly' ? '' : 'is-outlined'}`} onClick={() => setFaqReportFilter('weekly')}>Weekly Report</button>                
                    <button className={`button is-rounded is-small is-primary ${faqReportFilter === 'monthly' ? '' : 'is-outlined'}`} onClick={() => setFaqReportFilter('monthly')}>Monthly Report</button>            
                    {/* <button className={`button is-rounded is-small is-primary ${faqReportFilter === 'manual' ? '' : 'is-outlined'}`} onClick={() => setFaqReportFilter('manual')}>Manual</button> */}
                </div> 

                {fillteredFaqReports.length > 0 && selectedFaqReportIndex > -1 && <div className="field is-grouped ml-3">
                    <button className={`button is-small is-primary`} 
                        disabled={selectedFaqReportIndex <= 0}
                        onClick={() => setSelectedFaqReportIndex(selectedFaqReportIndex - 1)}
                    >
                        <i className="material-icons">arrow_back_ios</i>
                    </button>
                    {fillteredFaqReports[selectedFaqReportIndex] && <div className='ml-3'>
                        {`${convertTimestampToDateString(fillteredFaqReports[selectedFaqReportIndex].start_date)} to ${convertTimestampToDateString(fillteredFaqReports[selectedFaqReportIndex].end_date)}`}
                    </div>}
                    <button className={`button is-small is-primary ml-3`}
                        disabled={selectedFaqReportIndex >= fillteredFaqReports.length - 1}
                        onClick={() => setSelectedFaqReportIndex(selectedFaqReportIndex + 1)}
                    >
                        <i className="material-icons">arrow_forward_ios</i>
                    </button>            
                </div>}
            </div>
            
            {fillteredFaqReports.length === 0 && <div className="column">
                <div className="card">
                <div className="notification is-warning">
                    No report available.
                </div>
                </div>
            </div>}

            

            {fillteredFaqReports.length > 0 && selectedFaqReportIndex > -1 && <div className="columns">
            
                <div className="column is-6">
                    <div className="card">
                        <div className={`${showFaqReport ? 'card-content' : 'is-hidden'}`}>
                            <canvas ref={faqChartRef}></canvas>
                        </div>
                        <div className={`${showFaqReport ? 'is-hidden' : 'notification is-warning'}`}>
                            {faqErrorMessage}
                        </div>
                    </div>
                </div>
                {selectedFaqCluster && <div className="column is-6">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">Topic: {selectedFaqCluster.questions_summary}</p>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                <label className='label'>Selected Queries</label>
                                <ul>
                                    {selectedFaqCluster.cluster_questions_samples.map(question => <li>{question.content}</li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>}

            
        </div>
    );
};

export default EngagementAnalytics;
