import { h } from 'preact';
import { useEffect, useState, useContext } from 'preact/hooks';
import GlobalStateContext from '../../context/GlobalStateContext';

import io from 'socket.io-client';
import { sendAPIRequest, stripe, chat_site_official_bot_id } from '../../utils';
import plusImg from '../../../assets/icons/plus.png';
import MinWidthWarning from '../../components/MinWidthWarning';

import BasicInfoTab from './BasicInfoTab';
import ThemeTab from './ThemeTab';
import QAFineTuneTab from './QAFineTuneTab';
import KnowledgeLinks from './KnowledgeLinks';
import ChatHistoryTab from './ChatHistoryTab';
import CollaboratorsTab from './CollaboratorsTab';

import EngagementAnalytics from './EngagementAnalytics';
						

const Console = () => {
	const { user_id } = useContext(GlobalStateContext);
	const [ profile, setProfile ] = useState({});
	const [ accountPlan, setAccountPlan ] = useState(null);

	const [ userInputTimer, setUserInputTimer ] = useState(null);

    const [ showSideMenu, setShowSideMenu ] = useState(true);
    // const [socket, setSocket] = useState(null);
	const [ hideContent, setHideContent ] = useState(false);
    
    const [ bots, setBots ] = useState([]);
	const [ showBotLimitReachedModal, setShowBotLimitReachedModal ] = useState(false);
    const [ selectedBotId, setSelectedBotId ] = useState(null);
	const [ selectedBot, setSelectedBot ] = useState(null);

	const [ configTab, setConfigTab ] = useState('basic_info');

	const botThemeDefaultSettings = {
		primaryColor: ['Primary Color', '#5654DF'],
		iconBackgroundColor: ['Icon Background', '#5654DF'],
		iconFontColor: ['Icon Front', '#FFFFFF'],
		clientTextMessageBackgroundColor: ['Client Message Background', '#5654DF'],
		botTextMessageBackgroundColor: ['Bot Message Background', '#F3F6F9'],
		clientTextMessageColor: ['Client Message Color', '#FFFFFF'],
		botTextMessageColor: ['Bot Message Color', '#000000'],
	};

    useEffect(async () => {
		await loadProfile();
		await loadAssistantBots();
		// reset the selected bot to system bot
		
		return () => {
			window.siteChatDataLayer.push({
				event: 'chatInit',
				botId: chat_site_official_bot_id,
			})
		}
	}, [])

	useEffect(() => {
		if (selectedBotId) {
			setSelectedBot(bots.find((bot) => bot._id === selectedBotId));
			window.siteChatDataLayer.push({
				event: 'chatInit',
				botId: selectedBotId,
			});
		}
	}, [selectedBotId])

	useEffect(() => {
		// Clean up the timer on unmount
		return () => clearTimeout(userInputTimer);
	}, [userInputTimer]);

	const loadProfile = async () => {
		const profileResponse = await sendAPIRequest(`/api/v1/users/${user_id}/profile`, 'GET');
        setProfile(profileResponse);
		const accountPlanResponse = await sendAPIRequest(`/api/v1/users/${user_id}/account_plan`, 'GET');
        setAccountPlan(accountPlanResponse);
	}

	const loadAssistantBots = async () => {
		try {
			const response = await sendAPIRequest(`/api/v1/users/${user_id}/bots`, 'GET');
			setBots(response);
			if (response.length) {
				if (response.some((bot) => bot._id === selectedBotId)) {
					setSelectedBotId(selectedBotId);
				} else {
					setSelectedBotId(response[0]._id);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const create_bot = async () => {
		if (bots.length >= accountPlan.number_of_bots) {
			setShowBotLimitReachedModal(true);
			return;
		}

		try {
			const response = await sendAPIRequest('/api/v1/bots/create', 'POST');
			await loadAssistantBots();
			setSelectedBotId(response._id);
		} catch (err) {
			console.log(err);
		}
	}

	const edit_selected_bot = async (key, value) => {
		setSelectedBot((prevSelectedBot) => ({
			...prevSelectedBot,
			[key]: value,
		}));
		// update bot list on the side bar
		setBots((prevBots) => {
			const botIndex = prevBots.findIndex((bot) => bot._id === selectedBotId);
			const newBots = [...prevBots];
			newBots[botIndex][key] = value;
			return newBots;
		});
		clearTimeout(userInputTimer);
		setUserInputTimer(setTimeout(() => saveBot(key, value), 2000)); // Save after 2 seconds of inactivity
	}

	const saveBot = async (key, value) => {
		try {
			const response = await sendAPIRequest(`/api/v1/bots/${selectedBotId}`, 'PUT', { 
				...selectedBot,
				[key]: value 
			});
		} catch (err) {
			console.log(err);
		}
	};

    return (
        <div>
			<MinWidthWarning 
				minWidth={1200} 
				onWidthCheck={(isNarrowScreen) => setHideContent(isNarrowScreen)}
			/>

            { !hideContent && <div class="columns is-mobile" 
				style={{
					paddingLeft: '1.5rem',
					paddingRight: '1.5rem',
					height: '100vh',
				}}
			>
            {showSideMenu ? 
				(<aside
					style={{
						width : '180px',
						borderRight: '2px solid #ECECEC',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<p class="menu-label mt-3">
						<i class="material-icons" 
							style="vertical-align: middle;"
							onClick={() => setShowSideMenu(false)}
						>menu_open </i>  My Bots 
					</p>

					<ul class="menu-list"
						style={{
							height: '80%',
							overflowY: 'scroll',
						}}
					>
						<li>
						<a onClick={create_bot}>
							<div className="media" style={{ alignItems: 'center', display: 'flex' }}>
								<figure className="media-left" style={{ marginRight: '10px' }}>
									<p className="image is-24x24" style={{ margin: '0' }}>
										<img src={plusImg} alt=""
											className="media-item"
											style={{
												width: '24px',
												borderRadius: '50%',
												display: 'block',
											}}
										/>
									</p>
								</figure>
								<div className="media-content" style={{ display: 'flex', alignItems: 'center' }}>
									<p style={{ fontWeight: 'bold', margin: '0' }}>Create a Bot</p>
								</div>
							</div>
						</a>
						</li>
						{bots.map((bot) => (
							<li>
								<a
									className={bot._id === selectedBotId ? 'navbar-item has-text-primary' : ''}
									style={bot._id === selectedBotId ? {
											background: '#EFFFFC',
											fontWeight: 'bold',
										} : {}
									}
									onClick={() => { setSelectedBotId(bot._id) }}
								>
									<span>{bot.botname} <br /> ({bot._id})</span>
								</a>
							</li>
						))}
					</ul>

					<div class="is-flex-grow-1"></div>
					<hr class="navbar-divider" style={{ margin: '0' }} />					
					<ul class="menu-list">
						<li><a href="/console/account">
							<span class="subtitle is-6">{profile.name || profile.email || 'Account'}</span>
						</a></li>
					</ul>
				</aside>)
				:
				(<aside class={`column`}
					style={{
						borderRight: '2px solid #ECECEC',
						height: '100%'
					}}
				>
					<p class="menu-label  mt-3">
						<i class="material-icons" 
						style="vertical-align: middle;"
						onClick={() => setShowSideMenu(true)}
					>menu</i>
					</p>
				</aside>)
				}

                <div class={`column ${showSideMenu ? 'is-9' : 'is-12'}`}
					style={{
						height: '100%',
						overflowY: 'scroll',
					}}
				>
					{selectedBotId && <div>
						<div class="tabs is-centered">
							<ul>
								<li class={configTab == 'basic_info' ? 'is-active' : '' }><a onClick={() => setConfigTab('basic_info')}>Basic Info</a></li>
								<li class={configTab == 'theme' ? 'is-active' : '' }><a onClick={() => setConfigTab('theme')}>Theme</a></li>
								{/* <li class={configTab == 'knowledge' ? 'is-active' : '' }><a onClick={() => setConfigTab('knowledge')}>Knowledge</a></li> */}
								<li class={configTab == 'qa_fine_tune' ? 'is-active' : '' }><a onClick={() => setConfigTab('qa_fine_tune')}>Q&A Fine Tune</a></li>
								<li class={configTab == 'knowledge_links' ? 'is-active' : '' }><a onClick={() => setConfigTab('knowledge_links')}>Knowledge Links</a></li>
								<li class={configTab == 'chat_history' ? 'is-active' : '' }><a onClick={() => setConfigTab('chat_history')}>Chat History</a></li>
								<li class={configTab == 'engagement_analytics' ? 'is-active' : '' }><a onClick={() => setConfigTab('engagement_analytics')}>Engagement Analytics (Beta)</a></li>
								{/* <li class={configTab == 'collaborators' ? 'is-active' : '' }><a onClick={() => setConfigTab('collaborators')}>Collaborators</a></li> */}
							</ul>
						</div>

						{configTab == 'basic_info' && <BasicInfoTab
							accountPlan={accountPlan}
							profile={profile}
							selectedBot={selectedBot}
							edit_selected_bot={edit_selected_bot}
						/>}

						{configTab == 'theme' && <ThemeTab
							selectedBot={selectedBot}
							edit_selected_bot={edit_selected_bot}
							botThemeDefaultSettings={botThemeDefaultSettings}
						/>}

						{configTab == 'qa_fine_tune' && <QAFineTuneTab
							selectedBot={selectedBot}
							edit_selected_bot={edit_selected_bot}
						/>}

						{configTab == 'knowledge_links' && <KnowledgeLinks
							selectedBot={selectedBot}
							edit_selected_bot={edit_selected_bot}
						/>}

						{configTab == 'chat_history' && <ChatHistoryTab
							selectedBot={selectedBot}
						/>}

						{configTab == 'engagement_analytics' && <div>
							<EngagementAnalytics
								bot_id={selectedBotId}
							/>
						</div>}

						{configTab == 'collaborators' && <CollaboratorsTab
							selectedBot={selectedBot}
						/>}
                    </div>}
                </div>
            </div>}

			{ !hideContent && showBotLimitReachedModal && <div class="modal is-active">
				<div class="modal-background"></div>
				<div class="modal-card">
					<header class="modal-card-head" style={{background: '#5ECEB3'}}>
						<p class="modal-card-title"></p>
						<button class="delete" aria-label="close" onClick={
							() => setShowBotLimitReachedModal(false)
						}></button>
					</header>
					<section class="modal-card">
						<div class="modal-card-body">
							You have reached the maximum number of bots allowed in your plan.
						</div>
					</section>
					<footer class="modal-card-foot">
						<button class="button is-primary"
							aria-label="close"
							onClick={() => {
								// debugger
								window.open(`${stripe['customer_portal_link']}?prefilled_email=${profile.email}`)
							}}>
								Switch Plan
						</button>
						<button class="button"
							aria-label="close"
							onClick={() => setShowBotLimitReachedModal(false)}>
								Close
						</button>
					</footer>
				</div>
			</div>}
        </div>
    )
};

export default Console;
