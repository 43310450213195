module.exports = {
    branch: 'release',
    serverAPIHost: 'https://api.site-chat.com',
    chat_site_official_bot_id: 'C-b80565bfa8',
    googleClientId: '600144624257-k1sdgmeakoqjqk30ssc00vuaq3lv78as.apps.googleusercontent.com',
    stripe: {
      'customer_portal_link': 'https://billing.stripe.com/p/login/9AQbLD39P8FwghO5kk',
      'pricing-table-id': 'prctbl_1OKyyAHdMxi1ezjP2VphxLRG',
      'publishable-key': "pk_live_51OKybLHdMxi1ezjPHQBqO0iOAStzzj0OFKDZOstqY3IqFqK9epO4KsrwVcbKPfWuhYuLWDDUG0gNVZkAKaGmD3R700tXG9Z8KF"
    },
    gitpages: {
      github: {
        url: 'https://Jiansen@github.com/Jiansen/ChatBubble.git',
        CNAME: 'www.site-chat.com',
        branch: 'gh-pages',
      }
    },
  };