import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import Prism from 'prismjs'; // Assuming you're using PrismJS for syntax highlighting
import 'prismjs/themes/prism-okaidia.css'; // Import the theme of your choice
import './style.css';

const CodeBlock = ({ code, language }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      Prism.highlightElement(ref.current);
    }
  }, [code, language]);

  return (
    <pre className="code-block">
      <code ref={ref} className={`language-${language}`}>
        {code}
      </code>
    </pre>
  );
};

export default CodeBlock;
