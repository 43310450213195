import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { sendAPIRequest, stripe, fetchImageBase64 } from '../../utils';
import AvatarEditor from '../../components/avatar_editor';
import CodeBlock from '../../components/code_block';

import aiStarIcon from '../../../assets/icons/ai_star.svg';

const BasicInfoTab = ({accountPlan, profile, selectedBot, edit_selected_bot}) => {
    // edit avatar modal
	const [ avatarEditorVisible, setAvatarEditorVisible ] = useState(false);
	const [ botIdCopiedMessageVisible, setBotIdCopiedMessageVisible ] = useState(false);
	const [ codeCopyModalVisible, setCodeCopyModalVisible ] = useState(false);

    const [ botSiteURL, setBoSiteURL ] = useState('');
    const [ botSiteURLErrorMessage, setBotSiteURLErrorMessage ] = useState('');
	const [ botSiteURLSuccessMessage, setBotSiteURLSuccessMessage ] = useState('');

    const [showEmailSupportModal, setShowEmailSupportModal] = useState(false);
    const [emailServiceProviders, setEmailServiceProviders] = useState('gmail');

    useEffect(() => {
        if (selectedBot) {
            setBoSiteURL(selectedBot.baseURL);
        }
    }, [selectedBot]);
    
    const handleSendEmail = (email, subject, body) => {
        let link = '';
        switch (emailServiceProviders) {
          case 'gmail':
            link = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            break;
          case 'yahoo':
            link = `http://compose.mail.yahoo.com/?to=${email}&subj=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            break;
          case 'outlook':
            link = `https://outlook.live.com/owa/?path=/mail/action/compose&to=${email}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            break;
          default:
            // Fallback to mailto if service is not supported
            link = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        }
        window.open(link, '_blank');
    };

    const updateSiteURL = async (e) => {
        const siteBaseURL = e.target.value;
        setBoSiteURL(siteBaseURL);

        if (siteBaseURL == '') {
            setBotSiteURLErrorMessage('Site URL is empty and will not be updated');
            return;
        }

        setBotSiteURLSuccessMessage('');
        setBotSiteURLErrorMessage('');

        // check if the site URL is valid
        if (!siteBaseURL.startsWith('http://') && !siteBaseURL.startsWith('https://')) {
			setBotSiteURLErrorMessage('Site Base URL must start with http:// or https://');
			setBotSiteURLSuccessMessage('');
			return;
		}
		if (siteBaseURL.endsWith('/')) {
			setBotSiteURLErrorMessage('Site Base URL must not end with /');
			setBotSiteURLSuccessMessage('');
			return;
		}
        // validate the URL
        const url = new URL(siteBaseURL);
        if (!url.hostname) {
            setBotSiteURLErrorMessage('Invalid Site Base URL');
            setBotSiteURLSuccessMessage('');
            return;
        }

        try{
            await edit_selected_bot('baseURL', siteBaseURL)
            setBotSiteURLSuccessMessage(`Site Base URL updated to ${siteBaseURL}. If it is a completed URL, Click Import/Update Knowledge to update the knowledge base.`);
            setBotSiteURLErrorMessage('');
        } catch (error) {
            const error_details = error.details;
            setBotSiteURLSuccessMessage('');
            setBotSiteURLErrorMessage(error_details);
        }        
    }

    return (
        <div>
        {selectedBot && (<AvatarEditor
            bot_id={selectedBot._id}
            avatarEditorVisible={avatarEditorVisible}
            avatarUpdateHandler={async (newAvatar) => {
                const avatarB64 = await fetchImageBase64(newAvatar);
                edit_selected_bot('avatar', avatarB64);
                setAvatarEditorVisible(false)
            }}
            closeAvatarEditorHandler={() => setAvatarEditorVisible(false)}
        />)}

        <div class="level">
            <div class="level-item has-text-centered">
                <figure class="image is-128x128">
                    <img class="is-rounded" 
                        src={selectedBot ? selectedBot.avatar : ''} 
                        alt="Default Bot Avatar"
                        onClick={() => setAvatarEditorVisible(true)}
                    />
                </figure>
            </div>
        </div>

        {botIdCopiedMessageVisible && <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="notification is-primary is-light">
                    Bot ID {selectedBot && selectedBot._id} copied to clipboard.
                </div>
            </div>
        </div>}

        {codeCopyModalVisible && <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head" style={{background: '#5ECEB3'}}>
                    <p class="modal-card-title"></p>
                    <button class="delete" aria-label="close" onClick={
                        () => setCodeCopyModalVisible(false)
                    }></button>
                </header>								
                <section class="modal-card">
                    <div class="modal-card-body">
                    <CodeBlock language="javascript" code={
`<html>
<head> 
</head>
<body>  
    <!-- Add this to the end of the body tag -->
    <!-- Site Chat Script and Tag -->
    <script data-bot-id='${selectedBot && selectedBot._id}' id="site-chat-script" 
    src="https://www.site-chat.com/lib/site-chat.js" 
    type="module">
    </script>
</body>
</html>`										
} 
                    />
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" 
                        aria-label="close" 
                        onClick={() => setCodeCopyModalVisible(false)}>
                            Close
                    </button>
                </footer> 
            </div>
        </div>}						
        
        {selectedBot && (<div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="label">Live Mode</label>
            </div>
            <div class="field-body">
                <div class="field">
                <p class="control">
                    <div className="buttons has-addons">
                        <button className={selectedBot['live_mode'] ? "button is-small is-success" : "button is-small"}
                            onClick={() => edit_selected_bot('live_mode', true)}
                        >On</button>
                        <button className={selectedBot['live_mode'] ? "button is-small" : "button is-small is-danger"}
                            onClick={() => edit_selected_bot('live_mode', false)}
                        >Off</button>
                    </div>
                </p>
                </div>
            </div>

            
        </div>)}
        {selectedBot && selectedBot['live_mode'] && accountPlan && !accountPlan.customised_theme && (<div class="help is-success">
            Upgrade to apply your customized theme on your sites. <a href={`${stripe['customer_portal_link']}?prefilled_email=${profile.email}`} target='_blank'>Click here</a>
        </div>)}

        <div class="field mt-3">
            <label class="label">Bot ID</label>
            <div className="field has-addons is-medium">
                <p className="control" style={{ width: '360px' }}>
                    <input className="input is-small" type="text" value={selectedBot ? selectedBot._id : ''} disabled />
                </p>            
                <p class="control">
                    <button class="button is-small"
                        onClick={() => {
                            navigator.clipboard.writeText(selectedBot._id);
                            setBotIdCopiedMessageVisible(true);
                            setTimeout(() => setBotIdCopiedMessageVisible(false), 2000);
                        }}
                    >
                        <i className="material-icons mr-2">content_copy</i> Copy
                    </button>
                </p>
                <p class="control">
                    <button class="button is-small"
                        onClick={() => setCodeCopyModalVisible(true)}
                    >
                        <i className="material-icons mr-2">code</i> Get Code
                    </button>
                </p>
                <p class="control">
                    <button class="button is-small"
                        onClick={() => {
                            window.open(`/b/${selectedBot && selectedBot._id}`, '_blank');
                    }}
                    >
                        <i className="material-icons mr-2">share</i> Profile
                    </button>
                </p>
                <p class="control">
                    <button class="button is-small"
                        onClick={() => setShowEmailSupportModal(true)}
                    >
                        <i className="material-icons mr-2">contact_support</i> Contact Support
                    </button>
                </p>
            </div>
        </div>

        {showEmailSupportModal && <div class="modal is-active">
                <div class="modal-background"></div>
                <div class="modal-card
                ">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Send the support team an email</p>
                        <button class="delete" aria-label="close" onClick={() => setShowEmailSupportModal(false)}></button>
                    </header>
                    <section class="modal-card-body">
                        <div className="field" style={{ display: 'flex', alignItems: 'center' }}>
                            <label className="label" style={{ marginRight: '10px' }}>Send email with</label>
                            <div className="control">
                                <div className="select is-small">
                                    <select value={emailServiceProviders} onChange={e => setEmailServiceProviders(e.target.value)}>
                                        <option value="gmail">Gmail</option>
                                        <option value="yahoo">Yahoo</option>
                                        <option value="outlook">Outlook</option>
                                        <option value="default">System Default</option>                                        
                                    </select>
                                </div>
                            </div>
                        </div>

                    </section>
                    <footer class="modal-card-foot">
                        <button class="button"
                            onClick={() => {
                                const subject = `Feedback and Support - ${profile.email} - ${selectedBot && selectedBot._id}`;                                
                                const body = `I would like to contact the support team regarding the following Bot:
Account Email: ${profile.email}
Bot ID: ${selectedBot && selectedBot._id}

[YOUR NEEDS HERE]
`
                                handleSendEmail('support@site-chat.com', subject, body);
                                setShowEmailSupportModal(false);
                            }}
                        >Send email</button>
                    </footer>
                </div>
            </div>}

        <div class="field">
            <label class="label">Site Base URL</label>
            <div class="help is-success">
                {botSiteURLSuccessMessage}
            </div>
            <div class="help is-danger">
                {botSiteURLErrorMessage}
            </div>
            <div className="field has-addons is-medium">            
                <p className="control" style={{ width: '360px' }}>
                    <input class="input is-small" type="text"
                        placeholder="https://www.example.com" 
                        value={ botSiteURL }
                        onInput={(e) => updateSiteURL(e) } />
                </p>
                <p class="control">
                    <button class="button is-small"
                        onClick={async () => {
                            // post('/api/v1/bots/{bot_id}/knowledge_update_from_base_url')
                            try {
                                await sendAPIRequest(`/api/v1/bots/${selectedBot._id}/knowledge_update_from_base_url`, 'POST', { baseURL: selectedBot.baseURL });
                                setBotSiteURLSuccessMessage('Knowledge import scheduled successfully. Please wait for a few minutes and check the Knowledge Links tab.');
                            } catch (error) {
                                const error_details = error.message;
                                setBotSiteURLErrorMessage(error_details);
                            }
                        }}
                    >
                        Import/Update Knowledge
                    </button>
                </p>
            </div>
        </div>
        
        <div class="field">
            <label class="label">Bot Name</label>
            <div class="control">
                <input class="input" type="text" placeholder="Name your bot"
                    value={selectedBot ? selectedBot.botname : ''}
                    onInput={(e) => {
                        const newName = e.target.value
                        window.siteChatDataLayer.push({
                            event: 'chatbotNameUpdate',
                            name: newName,
                        });
                        edit_selected_bot('botname', newName)
                    }}
                />
            </div>
        </div>

        <div class="field">
            <label class="label">Dialogue Title</label>
            <div class="control">
                <input class="input" type="text" placeholder="Title of the bot dialogue widge"
                    value={selectedBot ? selectedBot.dialogue_title : ''}
                    onInput={(e) => {
                        const title = e.target.value
                        window.siteChatDataLayer.push({
                            event: 'chatbotDialogueTitleUpdate',
                            dialogue_title: title,
                        });
                        edit_selected_bot('dialogue_title', title)
                    }}
                />
            </div>
        </div>
        
        <div class="field">
            <label class="label">Description</label>
            <div class="control">
                <textarea class="textarea" 
                    placeholder="Add a short description about your bot and service"
                    value={selectedBot ? selectedBot.description : ''}
                    onInput={(e) => edit_selected_bot('description', e.target.value)}
                ></textarea>
            </div>
        </div>

        <div class="field">
            <label class="label">Instructions
                <div class="button ml-2 is-small is-primary"
                    onClick={ async () => {
                        const instructions = await sendAPIRequest(`/api/v1/bots/${selectedBotId}/generate_bot_instructions`, 'POST', { description: selectedBot.description });
                        edit_selected_bot('instructions', instructions);
                    }}
                >
                    <img src= {aiStarIcon} 
                        style="width: 1rem; vertical-align: middle; margin-right: 0.5rem;" />
                    Generate
                </div>
            </label>
            
            <div class="control">
                <textarea class="textarea"
                    placeholder="What does this bot do? How does it behave? What should it avoid doing?"
                    value={selectedBot ? selectedBot.instructions : ''}
                    onInput={(e) => edit_selected_bot('instructions', e.target.value)}
                ></textarea>
            </div>
        </div>

        <div class="field">
            <label class="label">Conversation Starters
                <div class="button ml-2 is-small is-primary"
                    onClick={ async () => {
                        const conversation_starters = await sendAPIRequest(`/api/v1/bots/${selectedBotId}/generate_bot_conversation_starters`, 'POST');
                        edit_selected_bot('conversation_starters', conversation_starters);
                    }}
                >
                    <img src= {aiStarIcon} 
                        style="width: 1rem; vertical-align: middle; margin-right: 0.5rem;" />
                    Generate
                </div>
            </label>								
        </div>
        { selectedBot && [0,1,2,3].map((i) => (
            <div key={i} class="field has-addons">
                <p class="control is-expanded">
                    <input class="input"
                        type="text"
                        value={selectedBot.conversation_starters[i]}
                        placeholder=""
                        onInput={(e) => 
                            // concat the new value to the array
                            edit_selected_bot('conversation_starters', 
                                [...selectedBot.conversation_starters.slice(0, i), 
                                    e.target.value, 
                                    ...selectedBot.conversation_starters.slice(i + 1)]
                            )
                        }
                    />
                </p>
                <p class="control">
                    <button class="button ">
                        <i class="material-icons" 
                            style="vertical-align: middle;"
                            onClick={() => {
                                // remove the value from the array
                                edit_selected_bot('conversation_starters', 
                                    [...selectedBot.conversation_starters.slice(0, i),
                                        ...selectedBot.conversation_starters.slice(i + 1), ""]
                                )
                            }}
                        >close </i> 
                    </button>
                </p>
            </div>
        ))}

        <div class="field">
            <label class="label">Support Email</label>
            <div class="help is-success">
                Enter your support email, reload, and test with the email icon below the last bot message in the chat box.
            </div>
            <input class="input" type="text" placeholder="support@your-email.com"
                    value={selectedBot ? selectedBot.support_email : ''}
                    onInput={(e) => {
                        const email = e.target.value
                        // validate the email
                        if (email == "" || (email.includes('@') && email.includes('.'))) {
                            edit_selected_bot('support_email', email)
                        }
                    }}
                />
        </div>

        <div class="field">
            <label class="label">Branding Text</label>
            {accountPlan && !accountPlan.customised_branding && (<div class="help is-success">
                Upgrade to use your customized branding in the dialogue box. <a href={`${stripe['customer_portal_link']}?prefilled_email=${profile.email}`} target='_blank'>Click here</a>
            </div>)}
            <div class="control">
                <input type="radio"
                    class="mr-1 is-disabled"
                    disabled={accountPlan && !accountPlan.customised_branding}
                    checked={selectedBot ? selectedBot.branding_text === 'default' : false}
                    onClick={(e) => {
                        const branding_text = 'default'
                        window.siteChatDataLayer.push({
                            event: 'chatbotBrandingTextUpdate',
                            branding_text: branding_text,
                        });
                        edit_selected_bot('branding_text', branding_text)
                    }}
                />Use Site Chat default branding text

                <div class="control">
                    <input type="radio" 
                        class="mr-1"
                        disabled={accountPlan && !accountPlan.customised_branding}
                        value={selectedBot ? selectedBot.branding_text : ''}
                        checked={selectedBot ? selectedBot.branding_text !== 'default' : false}
                        onClick={(e) => {
                            // if checked, set the branding text to the default
                            if (e.target.checked) {
                                const branding_text = ''
                                window.siteChatDataLayer.push({
                                    event: 'chatbotBrandingTextUpdate',
                                    branding_text: branding_text,
                                });
                                edit_selected_bot('branding_text', branding_text)
                            }
                        }}
                    />Use the following branding text 
                    <input type="text" 
                        class="input"
                        disabled={accountPlan && !accountPlan.customised_branding}
                        placeholder="Your cutomized branding text"
                        value={selectedBot ? selectedBot.branding_text : ''}
                        onChange={(e) => {
                            const branding_text = e.target.value
                            window.siteChatDataLayer.push({
                                event: 'chatbotBrandingTextUpdate',
                                branding_text: branding_text,
                            });
                            edit_selected_bot('branding_text', branding_text)
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
)}

export default BasicInfoTab;