import { h } from 'preact';

const PrivacyPolicyPage = () => {
    return (
        <div className="container">
            <section className="section">
                <h1 className="title">Terms of use</h1>
                <p className="subtitle mb-6">Updated: December 01, 2023</p>
                <div className="content">

                    <p>Thank you for choosing <strong>Site Chat</strong>!</p>

                    <p>These Terms of Use ("Terms") govern your use of the services provided by Ziida (The COMPANY) or our affiliates, which includes our software, tools, developer services, data, documentation, and websites (collectively, the "Services"). By accessing or using our Services, you agree to abide by these Terms. The Terms encompass our <strong>Service Terms</strong>, <strong>Sharing & Publication Policy</strong>, <strong>Usage Policies</strong>, and any other documentation, guidelines, or policies we may provide to you. Our
                        <strong><a href='/privacy-policy' class="ml-1">Privacy Policy</a></strong> details how we collect, store, and utilize personal data.</p>

                    <h2 className="subtitle">1. Registration and Access</h2>
                    <ul>
                        <li>To use our Services, you must be at least 13 years of age. If you are under 18, it is mandatory to obtain consent from a parent or legal guardian.</li>
                        <li>If you are representing another individual or entity while using the Services, you must possess the authority to accept these Terms on their behalf.</li>
                        <li>All the information provided during registration should be accurate and up-to-date. It's essential to ensure the security of your account credentials. The COMPANY is not liable for any unauthorized access resulting from your negligence in safeguarding your credentials.</li>
                        <li>Sharing of account details or access credentials outside your organization is strictly prohibited. You will be held responsible for all activities that transpire under your account.</li>
                        <li>We are pleased to provide our services exclusively to residents and entities located in the countries, regions, and territories listed on our <strong><a href='/supported-countries'>Supported Countries</a></strong> page. Access is restricted to users from these specified locations. Please note that we regularly update this list.</li>
                    </ul>


                    <h2 className="subtitle mb-6">2. Usage Requirements</h2>

                    <h3 className="title is-4">(a) Use of Services</h3>
                    <ul>
                        <li>You are granted a non-exclusive right to access and utilize the Services, in line with these Terms. It is imperative that you adhere to these Terms and all relevant laws while using the Services.</li>
                        <li>All rights, titles, and interests in the Services belong to The COMPANY and its affiliates. Any unauthorized duplication or replication is strictly forbidden.</li>
                    </ul>

                    <h3 className="title is-4">(b) Feedback</h3>
                    <ul>
                        <li>At <strong>Site Chat</strong>, we value feedback, including comments, ideas, proposals, and suggestions for enhancements. By sharing feedback, you grant The COMPANY the permission to use, modify, and implement it without any obligation for compensation.</li>
                    </ul>

                    <h3 className="title is-4">(c) Restrictions</h3>
                    <ul>
                        <li>The following actions are strictly prohibited:
                            <ul>
                                <li>Infringing, misappropriating, or violating any third party's rights.</li>
                                <li>Trying to reverse assemble, reverse compile, decompile, or translate any of the Service's source code or underlying components.</li>
                                <li>Using the Service's output to develop competing models against <strong>Site Chat</strong> and/or The COMPANY.</li>
                                <li>Extracting data or output from the Services through automated or programmatic means, including but not limited to scraping, web harvesting, or web data extraction.</li>
                                <li>Misrepresenting output from the Services as human-generated when it's machine-generated.</li>
                                <li>Trading, selling, or transferring API keys without prior authorization from The COMPANY.</li>
                                <li>Sending any data related to children under 13 or breaching the age of digital consent regulations.</li>
                            </ul>
                        </li>
                        <li>It is essential to comply with any rate limits or other specifications detailed in our documentation.</li>
                        <li>The Services are exclusively available in the regions currently supported by <strong>Site Chat</strong> in the US and EU.</li>
                    </ul>

                    <h3 className="title is-4">(d) Third-Party Services</h3>
                    <ul>
                        <li>Any third-party software, services, or products integrated with or used alongside the Services fall under their respective terms. The COMPANY holds no responsibility for any third-party products or their performances.</li>
                    </ul>



                    <h2 className="subtitle mb-6">3. Content</h2>

                    <h3 className="title is-4">(a) Your Content</h3>
                    <ul>
                        <li>You have the liberty to submit input to the Services ("Input") and will receive output generated by the Services based on this Input ("Output"). Both Input and Output are referred to as "Content".</li>
                        <li>While The COMPANY ensures the privacy and security of the Content, you retain ownership rights over the Input. Subject to your adherence to these Terms, The COMPANY assigns to you all rights in and to the Output. This implies that you can utilize the Content for any purpose, including commercial uses, as long as you remain compliant with these Terms.</li>
                        <li>The COMPANY may use the Content to enhance, maintain, and provide the Services, adhere to legal requirements, and enforce its policies. You are responsible for the accuracy and legality of the Content and ensuring it does not violate these Terms or any other legal stipulations.</li>
                    </ul>

                    <h3 className="title is-4">(b) Similarity of Content</h3>
                    <ul>
                        <li>Due to the machine learning mechanisms underlying the Services, Outputs may not always be unique. For instance, multiple users might receive similar or identical responses for common queries. Outputs generated for different users will not be recognized as unique Content for any specific user.</li>
                    </ul>

                    <h3 className="title is-4">(c) Use of Content to Improve Services</h3>
                    <ul>
                        <li>The COMPANY ensures that Content from the API ("API Content") is not used to refine or augment our Services. However, Content from other facets of the Services ("Non-API Content") might be employed to enhance our Services.</li>
                    </ul>

                    <h3 className="title is-4">(d) Accuracy</h3>
                    <ul>
                        <li>Machine learning and artificial intelligence are complex and evolving domains. While The COMPANY is dedicated to the continuous improvement of <strong>Site Chat</strong>, the probabilistic nature of machine learning might occasionally lead to Outputs that aren't entirely accurate or reflective of actual entities or facts. Users are encouraged to assess the accuracy of Outputs and, when necessary, involve human judgment.</li>
                    </ul>


                    <h2 className="subtitle mb-6">4. Fees and Payments</h2>

                    <h3 className="title is-4">(a) Fees and Billing</h3>
                    <ul>
                        <li>For availing the Services, you're required to pay the stipulated fees ("Fees") as per the rates mentioned on our pricing page or as otherwise mutually agreed upon in writing.</li>
                        <li>In the event of any pricing discrepancies or errors, The COMPANY reserves the right to make corrections, even if an invoice has been generated or payment has been made.</li>
                        <li>It's crucial to furnish accurate billing details, including a legitimate and authorized payment method. The designated payment method will be billed periodically based on the agreed terms.</li>
                        <li>If any issues arise with your payment completion, The COMPANY will notify you and may withhold access to the Services until due payment is made.</li>
                        <li>Payments shall be remitted in GBP or the designated currencies as specified and are payable immediately upon issuance of the invoice. All payments made under this Agreement are irrevocable and non-refundable, except as expressly provided herein.</li>
                    </ul>

                    <h3 className="title is-4">(b) Taxes</h3>
                    <ul>
                        <li>Unless explicitly mentioned, the Fees do not encompass any local, state, federal, or international taxes or duties ("Taxes"). All such Taxes associated with the Services will be your responsibility. The COMPANY can invoice you for such Taxes, and you're expected to pay them promptly and provide any necessary documentation.</li>
                    </ul>

                    <h3 className="title is-4">(c) Price Changes</h3>
                    <ul>
                        <li>The COMPANY holds the right to modify its pricing. Any such changes will be communicated to you via your registered account or our website. Price augmentations will come into effect 14 days post-notification, with exceptions for legal reasons or for Beta Services, which will be immediate. Any changes will influence the Fees levied on your account post the effective date of the modifications.</li>
                    </ul>

                    <h3 className="title is-4">(d) Disputes and Late Payments</h3>
                    <ul>
                        <li>In case of any discrepancies with the Fees or Taxes, kindly reach out to <a href="mailto:finance@site-chat.com">finance@site-chat.com</a> within thirty (30) days from the date of the contested invoice. Unsettled amounts may incur a monthly finance charge of 1.5% on the outstanding sum. If any amount remains unpaid, The COMPANY retains the right to restrict access to the Services post a written notification of the overdue payment.</li>
                    </ul>

                    <h3 className="title is-4">(e) Free Tier</h3>
                    <ul>
                        <li>Users are discouraged from creating multiple accounts to exploit the benefits of the free tier of the Services. If The COMPANY suspects any misuse, standard fees might be levied or access to the Services might be terminated.</li>
                    </ul>



                    <h2 className="subtitle mb-6">5. Confidentiality, Security, and Data Protection</h2>

                    <h3 className="title is-4">(a) Confidentiality</h3>
                    <ul>
                        <li>During your usage of the Services, you might gain access to confidential information pertaining to The COMPANY, its affiliates, or third parties. You're obligated to use this information solely for availing the Services and must not disclose it to any external parties. You're expected to safeguard this confidential information with the same diligence you'd protect your own, employing at least a reasonable level of care.</li>
                        <li>Confidential information includes, but isn't limited to, software, specifications, business strategies, and any other non-public data. However, this doesn't encompass data that's publicly known, already known to you without any confidentiality clauses, disclosed to you by a third party without any confidentiality obligations, or independently developed by you without the use of any confidential information.</li>
                        <li>Legal mandates or court orders that necessitate disclosure of confidential information are exceptions, provided you notify The COMPANY in advance and make genuine efforts to restrict the scope of the disclosure.</li>
                    </ul>

                    <h3 className="title is-4">(b) Security</h3>
                    <ul>
                        <li>You're expected to adopt reasonable security measures to safeguard your access to the Services. In the event of identifying any security vulnerabilities or breaches linked to your usage, you're required to immediately notify The COMPANY and provide relevant details.</li>
                    </ul>

                    <h3 className="title is-4">(c) Processing of Personal Data</h3>
                    <ul>
                        <li>If the Services involve the processing of personal data, you are fully responsible for ensuring compliance with the General Data Protection Regulation (GDPR) and all other applicable laws. This includes providing necessary privacy notices, obtaining required consents for data processing, and ensuring that all data processing activities adhere strictly to legal requirements. The use of the <strong>Site Chat</strong> API for processing personal data as defined under GDPR or other relevant legislation is entirely your responsibility, and you must ensure full compliance with these regulations without exception.</li>
                    </ul>


                    <h2 className="subtitle mb-6">6. Term and Termination</h2>

                    <h3 className="title is-4">(a) Termination; Suspension</h3>
                    <ul>
                        <li>These Terms are activated upon your initial use of the Services and will persist until terminated. You can terminate these Terms anytime by discontinuing the use of the Services.</li>
                        <li>The COMPANY reserves the right to terminate these Terms by providing you with a 30-day prior notice. In instances where there's a violation of critical sections like Usage Requirements, Confidentiality, Security and Data Protection, or if changes arise due to third-party technological associations beyond our control, or to comply with any legal mandates, The COMPANY may terminate these Terms immediately without prior notice.</li>
                        <li>Your access to the Services may be suspended if you fail to comply with these Terms, pose a security threat, engage in suspicious activities that might make The COMPANY or any third party liable, or if we suspect fraudulent intentions in your usage.</li>
                    </ul>

                    <h3 className="title is-4">(b) Effect on Termination</h3>
                    <ul>
                        <li>Post termination, you're expected to cease all use of the Services and promptly return or, upon our directive, discard any confidential information in your possession. Sections of these Terms that inherently extend beyond termination will continue in effect, including but not limited to Content, Confidentiality, Security and Data Protection, and the general terms.</li>
                    </ul>



                    <h2 className="subtitle mb-6">7. Indemnification; Disclaimer of Warranties; Limitations on Liability</h2>

                    <h3 className="title is-4">(a) Indemnity</h3>
                    <ul>
                        <li>You agree to defend, indemnify, and shield The COMPANY, its affiliates, and their personnel from any claims, damages, and expenses (inclusive of legal fees) stemming from or related to your use of the Services. This includes, but is not limited to, your Content, any products or services you develop in tandem with the Services, or any violations of these Terms or applicable laws on your part.</li>
                    </ul>

                    <h3 className="title is-4">(b) Disclaimer</h3>
                    <ul>
                        <li><strong>Site Chat</strong> Services are extended "AS IS". Except where prohibited by law, The COMPANY, its affiliates, and licensors offer no explicit or implicit warranties regarding the Services. All warranties, including those of merchantability, fitness for a specific purpose, or non-infringement, and any warranties arising from dealings or trade usage are hereby disclaimed. The COMPANY does not guarantee uninterrupted, precise, error-free Services or that any content will remain secure or unaltered.</li>
                    </ul>

                    <h3 className="title is-4">(c) Limitations of Liability</h3>
                    <ul>
                        <li>NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT EXCEED ​​THE LOWER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 1 MONTH BEFORE THE LIABILITY AROSE OR FIFTY POUNDS (£50). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</li>

                        <li>Some countries and states do not allow the disclaimer of certain warranties or the limitation of certain damages, so some or all of the terms above may not apply to you, and you may have additional rights. In that case, these Terms only limit our responsibilities to the maximum extent permissible in your country of residence.</li>

                        <li>ZIIDA’S AFFILIATES, SUPPLIERS, LICENSORS, AND DISTRIBUTORS ARE INTENDED THIRD PARTY BENEFICIARIES OF THIS SECTION.</li>
                    </ul>

                    <h2 className="subtitle">8. Dispute Resolution</h2>
                    <p>You and The COMPANY concur on the following mandatory arbitration and class action waiver provisions:</p>

                    <h3 className="title is-4">(a) MANDATORY ARBITRATION</h3>
                    <ul>
                        <li>Both you and The COMPANY are committed to settling any present or past disputes relating to these Terms or our Services through final and binding arbitration. However, you reserve the right to opt out of these arbitration terms, and any subsequent modifications to these terms, by filling out <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/XoxvYupjCPawqUBv6">this form</a> within 30 days of agreeing to the arbitration terms or the pertinent amendments.</li>
                    </ul>

                    <h3 className="title is-4">(b) Informal Dispute Resolution</h3>
                    <ul>
                        <li>We would like to understand and try to address your concerns prior to formal legal action. Before either of us files a claim against the other, we both agree to try to resolve the Dispute informally. You agree to do so by sending us notice through <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/tyXvyCjNgW6PBK2L7">this form</a>. We will do so by sending you notice to the email address associated with your account. If a resolution isn't achieved within 60 days, formal proceedings may be initiated. The statute of limitations will be paused during the 60-day resolution window. For EU residents, the European Commission provides an online dispute resolution platform, accessible at <a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a></li>
                    </ul>

                    <h3 className="title is-4">(c) Arbitration Forum</h3>
                    <ul>
                        <li>Should a Dispute arise that we cannot resolve mutually, either party may initiate arbitration proceedings with a recognized UK arbitration institution under its prevailing arbitration rules and procedures. In the event that we cannot agree on an arbitrator, the appointment will be made by the Law Society of England and Wales, through the office of its president. This process aligns with the guidelines and principles set out in the Arbitration Act 1996. We will not seek recovery of attorneys’ fees and costs in the arbitration unless the arbitrator deems the claim to be frivolous. These Terms are subject to and governed by UK law, which will oversee the interpretation, enforcement of these arbitration provisions, and any resultant arbitration process.</li>
                    </ul>

                    <h3 className="title is-4">(d) Arbitration Procedures</h3>
                    <ul>
                        <li>The arbitration will primarily be conducted via videoconference. However, if the arbitrator deems it necessary to conduct a hearing in person, the location will be mutually agreed upon within London, or as determined by the arbitrator. The arbitration will be conducted by a sole arbitrator, who will be a retired judge or a solicitor or barrister qualified to practice law in the United Kingdom. The arbitrator will have exclusive authority to resolve any Dispute, except for matters concerning enforceability, validity of any class action waiver, or requests for public injunctive relief, which may fall under the jurisdiction of the courts of London. Any offers of settlement will not be disclosed to the arbitrator by either party until after the arbitrator has determined the final award, if any. The arbitrator will have the authority to issue decisions that conclusively resolve all or part of the Dispute.</li>

                        <li>These revisions align the terms with UK legal standards and practices, ensuring compliance with the Arbitration Act 1996 and appropriateness for a UK-based company operating in London.</li>
                    </ul>

                    <h3 className="title is-4">(e) Exceptions</h3>
                    <ul>
                        <li>This arbitration clause does not encompass individual claims processed in small claims court or any claims where one seeks injunctive relief for intellectual property infringement or unauthorized use of the Services.</li>
                    </ul>

                    <h3 className="title is-4">(f) NO CLASS ACTIONS</h3>
                    <ul>
                        <li>Disputes must be raised on an individual basis. Class actions, private attorney general actions, and consolidation with other arbitrations are not permitted. If a dispute is taken to court rather than arbitration, both parties waive their right to a jury trial.</li>
                    </ul>

                    <h3 className="title is-4">(g) Severability</h3>
                    <ul>
                        <li>Should any part of this Section 8 be deemed illegal or unenforceable, the remainder will stay in effect. Any findings of partial illegality that would permit class or representative arbitration would render this entire Section 8 void.</li>
                    </ul>


                    <h2 className="subtitle mb-6">9. General Terms</h2>

                    <h3 className="title is-4">(a) Relationship of the Parties</h3>
                    <p>The Terms do not constitute a partnership, joint venture, or agency relationship between you and The COMPANY. Both parties function as independent contractors, and neither has the authority to bind the other or incur obligations on the other's behalf without prior written consent.</p>

                    <h3 className="title is-4">(b) Use of Brands</h3>
                    <p>Without prior written approval from The COMPANY, you're prohibited from using any of its names, logos, or trademarks.</p>

                    <h3 className="title is-4">(c) UK Government Entities</h3>
                    <p>The Services have been developed exclusively through private investment and qualify as commercial computer software and associated documentation as per UK regulations.</p>

                    <h3 className="title is-4">(d) Copyright Complaints</h3>
                    <p>In case of perceived infringement of your intellectual property rights, notify The COMPANY at the address provided below.</p>
                    <address>
                        Ziida<br />
                        Unit8, Dock Offices<br />
                        Surrey Quays Road<br />
                        London<br />
                        United Kingdom<br />
                        SE16 2XU<br />
                        Attn: Legal Department / Copyright Agent<br />
                    </address>
                    <p class="mt-2">Any claim regarding copyright infringement should encompass the following details:</p>
                    <ul>
                        <li>Physical or electronic signature of the authorized person.</li>
                        <li>A description of the copyrighted work believed to be infringed.</li>
                        <li>Location of the material in question on the site.</li>
                        <li>Your contact details - address, phone number, and email.</li>
                        <li>A declaration, under penalty of perjury, that the information in your notice is accurate and that you either own the copyright or are authorized to act on their behalf.</li>
                    </ul>

                    <h3 className="title is-4">(e) Assignment and Delegation</h3>
                    <p>Without written consent from The COMPANY, you cannot delegate or assign any rights or obligations under these Terms. Any attempted assignment or delegation will be deemed null and void. The COMPANY retains the right to assign these Terms in scenarios like mergers, acquisitions, or significant asset sales.</p>

                    <h3 className="title is-4">(f) Modifications</h3>
                    <p>The COMPANY may amend these Terms by posting an updated version on the website. If any update significantly impacts your rights or obligations, you'll be notified either through the email associated with your account or through in-product notifications. Such changes will become effective no sooner than 30 days after notification. Your continued use of the Services post any changes signifies your agreement to these changes.</p>

                    <h3 className="title is-4">(g) Waiver and Severability</h3>
                    <p>Failure by The COMPANY to act immediately upon your non-compliance doesn't signify a waiver of any rights. If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in full force.</p>

                    <h3 className="title is-4">(h) Export Controls</h3>
                    <p>The Services must not be re-exported, exported, or utilized for the benefit of sanctioned countries or individuals on the UK, EU, or US restricted lists. You assert that you are not located in any sanctioned regions or on any restricted lists and commit to comply with all relevant export laws and regulations.</p>

                    <h3 className="title is-4">(i) Equitable Remedies</h3>
                    <p>Recognizing the potential irreparable harm to The COMPANY in the event of your breach, The COMPANY reserves the right to seek injunctive relief against you in addition to other available remedies.</p>

                    <h3 className="title is-4">(joint) Entire Agreement</h3>
                    <p>These Terms, inclusive of all integrated policies, represent the entire understanding between you and The COMPANY concerning the Services, superseding any prior agreements or understandings.</p>

                    <h3 className="title is-4">(k) Jurisdiction, Venue and Choice of Law</h3>
                    <p>These Terms are governed by UK law. Except as detailed in the "Dispute Resolution" section, any claims arising from these Terms will exclusively be brought to courts located in London, UK.</p>



                    <h2 className="subtitle mb-6">10. Additional Provisions for EU Users</h2>

                    <h3 className="title is-4">(a) Data Protection and Privacy</h3>
                    <p>The COMPANY complies with the General Data Protection Regulation (GDPR) in relation to the collection, use, and retention of personal data from European Union member countries. If you are a resident of the EU, you have specific rights regarding your personal data, such as the right to access, correct, or delete your personal data maintained by <strong>Site Chat</strong>. More details can be found in our <strong><a href='/privacy-policy'>Privacy Policy</a></strong>.</p>

                    <h3 className="title is-4">(b) Right to Cancel</h3>
                    <p>EU consumers have the right to cancel certain online purchases within 14 days. This “cooling off” period expires 14 days after the day you received your goods. If the cooling-off period expires on a non-working day, your deadline is extended till the next working day. To exercise this right, you must inform us of your decision to cancel your contract by a clear statement, including details of your name, geographical address, details of the order you wish to cancel, and where available, your phone number and email address.</p>

                    <h3 className="title is-4">(c) Geographical Limitations</h3>
                    <p>While <strong>Site Chat</strong> is available to users in the EU, there may be certain services or features that are not available in all countries or regions. Please refer to our Service Availability List for more details.</p>

                    <h3 className="title is-4">(d) Consumer Protection</h3>
                    <p>As a consumer, nothing in these Terms will affect your rights to rely on any of our commitments that are confirmed in writing or any applicable statutory rights.</p>

                    <h3 className="title is-4">(e) Language</h3>
                    <p>These Terms, as well as any associated terms or documentation, are available in English. In case of any discrepancies between the English version and translated versions, the English version shall prevail.</p>



                </div>
            </section>
        </div>
    );
}

export default PrivacyPolicyPage;
