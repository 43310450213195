import { h } from 'preact';

const CookiePolicyPage = () => {
    return (
        <div className="container">
            <section className="section">
                <h1 className="title">Cookie Policy</h1>
                <p className="subtitle">Updated: December 01, 2023</p>

                <div className="content">
                    <h2>1. Introduction</h2>
                    <p>This Cookie Policy explains how <strong>Site Chat</strong> ("we", "our", "us") uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

                    <h2>2. What are cookies?</h2>
                    <p>Cookies are small pieces of information stored on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
                    <p>Cookies set by the website owner are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>

                    <h2>3. Why do we use cookies?</h2>
                    <ul>
                        <li><strong>Essential cookies</strong>: These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</li>
                        <li><strong>Performance and functionality cookies</strong>: These cookies are used to enhance the performance and functionality of our website but are non-essential to its use. However, without these cookies, certain functionality may become unavailable.</li>
                        <li><strong>Analytics and customization cookies</strong>: These cookies collect information that is used either in aggregate form to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our website for you.</li>
                        <li><strong>Advertising cookies</strong>: These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring ads are properly displayed, and in some cases selecting advertisements based on your interests.</li>
                    </ul>

                    <h2>4. How can I control cookies?</h2>
                    <p>You have the right to decide whether to accept or reject cookies. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website, though some functionality and areas of our website may be restricted.</p>
                    <p>Most web browsers allow you to manage preferences for cookies directly within your browser settings. For more information on how to manage cookies in your browser, consult your browser's documentation.</p>

                    <h2>5. How often will this Cookie Policy be updated?</h2>
                    <p>We may update this Cookie Policy from time to time to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>

                    <h2>6. Where can I get further information?</h2>
                    <p>For more details on cookies, please visit <a href="https://allaboutcookies.org/" rel="noopener noreferrer" target="_blank">All About Cookies</a>.</p>
                </div>
            </section>
        </div>
    );
}

export default CookiePolicyPage;
