import { h, Component } from 'preact';
import { useState } from 'preact/hooks';
import { SketchPicker } from 'react-color';
import ColorPickerIcon from '../../../assets/icons/color-wheel.svg';

import './style.css';

const ColorPicker = ({color, setColor}) => {
    const [showPicker, setShowPicker] = useState(false);

    const handleChangeComplete = (newColor) => {
        if (/^#([0-9A-F]{3}){1,2}$/i.test(newColor.hex)) {
          setColor(newColor.hex);
        }
    };

    return (
      <div>
        <div className="field has-addons color-picker-container">
          <div className="control">
            <input className="input is-small" type="text" 
              value={color} 
              onChange={(e) => setColor(e.target.value )}
            />
          </div>
          <div className="control ml-2">
            <img 
                class="icon"
                  src={ColorPickerIcon} alt="Toggle Color Picker" 
                  style={{ height: '2rem' }}
                  onClick={(() => setShowPicker(!showPicker))}
                />
          </div>
          {showPicker && 
            <div className="color-picker">
              <button className="button is-small" 
                  onClick={() => setShowPicker(false)}>
                  <span className="material-icons">close</span>
              </button>
              <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
            </div>
          }
        </div>
      </div>
    )
}

export default ColorPicker;
